var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useMutation, useQuery } from 'react-query';
import { StoreCourseProgressModel } from 'renderer/models';
import { StoreCourseModel } from 'renderer/models/StoreCourseModel';
import { QUERY_CACHE } from 'renderer/queries';
import { RestClient } from 'renderer/utils';
var mutationConfig = {
    onSuccess: function () {
        // TODO: 필터에 맞춰서 optimise
        QUERY_CACHE.invalidateQueries(['store']);
        QUERY_CACHE.invalidateQueries(['storePoint']);
        QUERY_CACHE.invalidateQueries(['getStoreCourses']);
    }
};
export var useGetCourses = function (_a, config) {
    var storeId = _a.storeId;
    var queryKey = ['getStoreCourses', storeId];
    return useQuery(queryKey, function (options) {
        var storeId = options.queryKey[1];
        return RestClient.getInstance()
            .get("/stores/".concat(storeId, "/courses"))
            .then(function (result) {
            return result.data.map(function (item) { return new StoreCourseModel(item); });
        });
    }, __assign(__assign({}, config), { staleTime: 60000 }));
};
export var useAddStoreCourseProgress = function (config) {
    return useMutation(function (payload) {
        return RestClient.getInstance()
            .post("/stores/".concat(payload.storeId, "/courses/").concat(payload.storeCourseId, "/progress"), payload)
            .then(function (result) { return new StoreCourseProgressModel(result.data); });
    }, __assign(__assign({}, mutationConfig), config));
};
