var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { BaseModel } from 'renderer/models';
// FIXME: circular dependycy
import { MESSAGE_VARIABLES, DEFAULT_VARIABLES } from './MessageTemplateModel';
import * as dayjs from 'dayjs';
import { getByteLength } from 'renderer/utils';
export var AUTO_MESSAGE_KEYS;
(function (AUTO_MESSAGE_KEYS) {
    AUTO_MESSAGE_KEYS["PENDING_RESERVATION_NOTIFICATION"] = "pending_reservation_notification";
    AUTO_MESSAGE_KEYS["RESERVATION_NOTIFICATION"] = "reservation_notification";
    AUTO_MESSAGE_KEYS["RESERVATION_CONFIRM"] = "reservation_confirm";
    AUTO_MESSAGE_KEYS["RESERVATION_CANCEL"] = "reservation_cancel";
    AUTO_MESSAGE_KEYS["RESERVATION_NOSHOW"] = "reservation_noshow";
    AUTO_MESSAGE_KEYS["SALES_COMPLETE_AFTER"] = "sales_complete_after";
    AUTO_MESSAGE_KEYS["SALES_COMPLETE"] = "sales_complete";
    AUTO_MESSAGE_KEYS["SALES_CANCEL"] = "sales_cancel";
    AUTO_MESSAGE_KEYS["SALES_PREPAID_BUY"] = "sales_prepaid_buy";
    AUTO_MESSAGE_KEYS["SALES_PREPAID_USED"] = "sales_prepaid_used";
    AUTO_MESSAGE_KEYS["SALES_PREPAID_BUY_CANCEL"] = "sales_prepaid_buy_cancel";
    AUTO_MESSAGE_KEYS["SALES_PREPAID_USED_CANCEL"] = "sales_prepaid_used_cancel";
    AUTO_MESSAGE_KEYS["SALES_TICKET_BUY"] = "sales_ticket_buy";
    AUTO_MESSAGE_KEYS["SALES_TICKET_USED"] = "sales_ticket_used";
    AUTO_MESSAGE_KEYS["SALES_TICKET_BUY_CANCEL"] = "sales_ticket_buy_cancel";
    AUTO_MESSAGE_KEYS["SALES_TICKET_USED_CANCEL"] = "sales_ticket_used_cancel";
    AUTO_MESSAGE_KEYS["SALES_CUSTOMER_POINT_BUY"] = "sales_customer_point_buy";
    AUTO_MESSAGE_KEYS["SALES_CUSTOMER_POINT_USED"] = "sales_customer_point_used";
    AUTO_MESSAGE_KEYS["SALES_CUSTOMER_POINT_CANCEL"] = "sales_customer_point_cancel";
    AUTO_MESSAGE_KEYS["SALES_CUSTOMER_POINT_USED_CANCEL"] = "sales_customer_point_used_cancel";
    AUTO_MESSAGE_KEYS["TICKET_PREPAID_MODIFIED"] = "ticket_prepaid_modified";
    AUTO_MESSAGE_KEYS["TICKET_OTHERS_MODIFIED"] = "ticket_others_modified";
    AUTO_MESSAGE_KEYS["CUSTOMER_POINT_MODIFIED"] = "customer_point_modified";
    AUTO_MESSAGE_KEYS["CUSTOMER_BIRTH"] = "customer_birth";
    AUTO_MESSAGE_KEYS["CUSTOMER_INACTIVE"] = "customer_inactive";
    AUTO_MESSAGE_KEYS["TICKET_OTHERS_EXPIRED"] = "ticket_others_expired";
    AUTO_MESSAGE_KEYS["TICKET_PREPAID_EXPIRED"] = "ticket_prepaid_expired";
    AUTO_MESSAGE_KEYS["SALES_REQUEST_REPEAT_VISIT"] = "sales_request_repeat_visit";
    AUTO_MESSAGE_KEYS["CUSTOMER_POINT_EXPIRED"] = "customer_point_expired";
    AUTO_MESSAGE_KEYS["SHARE_TICKET_USE"] = "share_ticket_use";
    AUTO_MESSAGE_KEYS["SHARE_TICKET_USE_CANCEL"] = "share_ticket_use_cancel";
})(AUTO_MESSAGE_KEYS || (AUTO_MESSAGE_KEYS = {}));
var getAfterSendAtOptionsItems = function (label, items) {
    // 10분 0.16666
    // 30분 0.5
    // 20분 0.33333
    // 40분 0.66666
    // 50분 0.83333
    return items.map(function (value) {
        return {
            label: "".concat(label, " ").concat(value === 0
                ? '즉시'
                : value >= 24
                    ? "".concat(value / 24, "\uC77C \uD6C4")
                    : value < 1
                        ? "".concat(value === 0.16666 ? "10\uBD84 \uD6C4" : "30\uBD84 \uD6C4")
                        : "".concat(value, "\uC2DC\uAC04 \uD6C4")),
            value: value
        };
    });
};
var AutoMessageSettingModel = /** @class */ (function (_super) {
    __extends(AutoMessageSettingModel, _super);
    function AutoMessageSettingModel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(AutoMessageSettingModel.prototype, "sendOptionString", {
        get: function () {
            var sendType = this.sendOption.type;
            if (sendType === 'after') {
                return this.sendOption.hour === 0
                    ? '즉시'
                    : "".concat(this.sendOption.hour >= 24
                        ? "".concat(this.sendOption.hour / 24, "\uC77C \uD6C4")
                        : this.sendOption.hour < 1
                            ? "".concat(this.sendOption.hour >= 0.5 ? 30 : 10, "\uBD84 \uD6C4")
                            : "".concat(this.sendOption.hour, "\uC2DC\uAC04 \uD6C4"));
            }
            else if (sendType === 'before') {
                return this.sendOption.hour === 0
                    ? '즉시'
                    : "".concat(this.sendOption.hour >= 24 ? "".concat(this.sendOption.hour / 24, "\uC77C \uC804") : "".concat(this.sendOption.hour, "\uC2DC\uAC04 \uC804"));
            }
            else if (sendType === 'fixed-after') {
                return "".concat(this.sendOption.day === 0 ? '당일' : "".concat(this.sendOption.day, "\uC77C \uD6C4"), "(").concat(dayjs(this.sendOption.sendAt).format('HH:mm'), ")");
            }
            else if (sendType === 'fixed-before') {
                return "".concat(this.sendOption.day === 0 ? '당일' : "".concat(this.sendOption.day, "\uC77C \uC804"), "(").concat(dayjs(this.sendOption.sendAt).format('HH:mm'), ")");
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AutoMessageSettingModel.prototype, "typeString", {
        get: function () {
            var _a;
            if (this.type === 'message') {
                // 무료거부:0800000000-인증번호:111111 44Bytes
                // (광고)땡땡땡땡땡 - 16Bytest
                var bytes = getByteLength((_a = this.content) === null || _a === void 0 ? void 0 : _a.body) + (this.isAd ? 44 + 16 : 0);
                return bytes >= 90 ? 'LMS' : 'SMS';
            }
            return '알림톡';
        },
        enumerable: false,
        configurable: true
    });
    AutoMessageSettingModel.AUTO_MESSAGE_KEYS = AUTO_MESSAGE_KEYS;
    return AutoMessageSettingModel;
}(BaseModel));
export { AutoMessageSettingModel };
var days = Array.from({ length: 181 }, function (v, i) { return i; });
// keyword
/**
 *
 * reservation (noti, confirm, cancel, cancel_store)
 * noshow (noti)
 * prepaid ( create, used, modify, cancel_create, cancel_used, expired)
 * ticket ( create, used, modify, cancel_create, cancel_used, expired)
 * customerPoint ( create, used, modify, cancel_create, cancel_used, expired)
 * sales (create)
 * user(birth)
 * user(last_visited_at)
 *
 */
export var AUTO_MESSAGE_TEMPLATE_LIST = [
    /**
     * 예약등록, 예약수정, 예약삭제
     */
    {
        type: 'sms',
        key: AutoMessageSettingModel.AUTO_MESSAGE_KEYS.PENDING_RESERVATION_NOTIFICATION,
        model: 'schedule',
        title: '예약 대기',
        isAd: false,
        content: {
            body: "#{\uACE0\uAC1D\uBA85}\uB2D8 #{\uC0C1\uC810\uBA85} \uC608\uC57D\uAE08 \uC548\uB0B4\uB4DC\uB9BD\uB2C8\uB2E4.\n#{\uACC4\uC88C\uBC88\uD638}\n#{\uC608\uC57D\uAE08}\n\uC785\uAE08\uC740 #{\uC608\uC57D\uAE08\uC785\uAE08\uAE30\uD55C}\uAE4C\uC9C0 \uC785\uAE08\uD574\uC8FC\uC138\uC694. \uC608\uC57D\uAE08\uC774 \uD655\uC778\uB418\uBA74 \uC608\uC57D\uD655\uC815\uBB38\uC790\uB97C \uBC1C\uC1A1\uD574\uB4DC\uB9BD\uB2C8\uB2E4.\n\n\u203B\uC8FC\uC758\uC0AC\uD56D\u203B\n- \uB2F9\uC77C \uB178\uC1FC \uBC29\uC9C0\uB97C \uC704\uD574 \uC608\uC57D\uAE08\uC744 \uBC1B\uACE0 \uC788\uC2B5\uB2C8\uB2E4. 10\uBD84\uC774\uC0C1 \uC9C0\uAC01, \uB178\uC1FC \uC2DC \uC608\uC57D\uC740 \uC790\uB3D9\uC73C\uB85C \uCDE8\uC18C\uB418\uBA70 \uC608\uC57D\uAE08\uC740 \uD658\uBD88\uB418\uC9C0 \uC54A\uC2B5\uB2C8\uB2E4.\n- \uC785\uAE08\uC790\uBA85\uC740 \uC608\uC57D\uACE0\uAC1D\uB2D8 \uC131\uD568(#{\uACE0\uAC1D\uBA85})\uACFC \uB3D9\uC77C\uD558\uAC8C \uC785\uB825\uD574\uC8FC\uC138\uC694. \uC131\uD568\uC774 \uBD88\uC77C\uCE58\uD560 \uACBD\uC6B0 \uC608\uC57D\uAE08 \uD655\uC778\uC774 \uB2A6\uC5B4\uC9C8 \uC218 \uC788\uC2B5\uB2C8\uB2E4."
        },
        data: {
            isFree: false,
            hasProductIds: false
        },
        sendAtOptions: [
            {
                label: '등록 후 알림',
                target: 'updatedAt',
                type: 'after',
                valueUnit: 'hour',
                items: getAfterSendAtOptionsItems('등록', [0, 0.16666, 0.5, 1, 2, 3]),
                sendMessageOnFailAlimtalk: true
            }
        ],
        variables: __spreadArray([
            MESSAGE_VARIABLES.RESERVATION_FEE_TIME_LIMIT,
            MESSAGE_VARIABLES.RESERVATION_DATE,
            MESSAGE_VARIABLES.MANAGER_NAME,
            MESSAGE_VARIABLES.RESERVATION_FEE,
            MESSAGE_VARIABLES.RESERVATION_PRODUCTS,
            MESSAGE_VARIABLES.RESERVATION_TIME_SPEND
        ], DEFAULT_VARIABLES, true),
        notices: [
            '예약대기 문자는 예약등록일시를 기준으로 발송됩니다.',
            '새로운 예약을 ‘예약대기’ 상태로 등록했을때 문자발송이 예약됩니다.',
            '등록된 예약의 예약상태를 ‘예약대기’로 변경했을 때 문자발송이 예약됩니다.',
            '‘예약대기’ 상태로 등록된 예약의 ‘예약시작시간’을 변경했을 때 문자발송이 예약됩니다.',
            '예약시작시간이 예약등록일시 또는 예약변경일시보다 과거일 경우에는 문자발송이 예약되지 않습니다.',
            '다른 예약상태로 수정 시, 발송되지 않은 예약대기 문자는 모두 발송 취소 됩니다.',
            '예약 삭제 시, 발송되지 않은 예약대기 문자는 모두 발송 취소 됩니다.',
            '단, 발송 5분전에는 예약발송이 취소되지 않습니다.',
            '문자내용을 편집할 경우 편집한 시점 이후에 예약된 문자부터 새 문자내용이 적용됩니다.',
            '문자내용을 편집해도 이미 예약된 문자에는 수정된 내용이 반영되지 않습니다. 이미 예약된 문자의 내용을 바꾸고 싶다면 문자내역에서 문자내용을 직접 편집해주세요.'
        ]
    },
    {
        type: 'sms',
        // timeType: ''
        key: AutoMessageSettingModel.AUTO_MESSAGE_KEYS.RESERVATION_NOTIFICATION,
        model: 'schedule',
        title: '예약 알림',
        isAd: false,
        content: {
            body: "#{\uACE0\uAC1D\uBA85}\uB2D8,\n#{\uC0C1\uC810\uBA85} \uC608\uC57D\uC774 \uB2E4\uAC00\uC654\uC2B5\uB2C8\uB2E4.\n\n\u203B\uC608\uC57D \uC815\uBCF4\u203B\n\uC608\uC57D\uC77C\uC2DC : #{\uC608\uC57D\uC77C\uC2DC}\n\uB2F4\uB2F9\uC790 : #{\uB2F4\uB2F9\uC790\uBA85}\n\uC608\uC57D\uC0C1\uD488 : #{\uC0C1\uD488}\n\uC18C\uC694\uC2DC\uAC04 : #{\uC18C\uC694\uC2DC\uAC04}\n\n\uB2A6\uC9C0 \uC54A\uAC8C \uBC29\uBB38 \uBD80\uD0C1\uB4DC\uB9BD\uB2C8\uB2E4. \uAC10\uC0AC\uD569\uB2C8\uB2E4."
        },
        data: {
            isFree: false,
            hasProductIds: false
        },
        sendAtOptions: [
            {
                label: '일시지정 알림',
                target: 'dateOption.startedAt',
                type: 'fixed-before',
                valueUnit: 'day',
                items: [0, 1, 2, 3, 7, 14, 21, 30, 60, 90].map(function (value) { return ({
                    label: value === 0 ? "\uC608\uC57D\uB2F9\uC77C" : "\uC608\uC57D".concat(value, "\uC77C \uC804"),
                    value: value
                }); }),
                sendMessageOnFailAlimtalk: true
            },
            {
                label: '진행 전 알림',
                target: 'dateOption.startedAt',
                type: 'before',
                valueUnit: 'hour',
                items: [1, 2, 3, 24, 48, 72].map(function (value) { return ({
                    label: "\uC608\uC57D ".concat(value >= 24 ? "".concat(value / 24, "\uC77C") : "".concat(value, "\uC2DC\uAC04"), " \uC804"),
                    value: value
                }); }),
                sendMessageOnFailAlimtalk: true
            }
        ],
        variables: __spreadArray([
            MESSAGE_VARIABLES.RESERVATION_DATE,
            MESSAGE_VARIABLES.MANAGER_NAME,
            MESSAGE_VARIABLES.RESERVATION_FEE,
            MESSAGE_VARIABLES.RESERVATION_PRODUCTS,
            MESSAGE_VARIABLES.RESERVATION_TIME_SPEND
        ], DEFAULT_VARIABLES, true),
        notices: [
            '예약알림 문자는 예약시작시간을 기준으로 발송됩니다.',
            '새로운 예약을 ‘예약확정’ 상태로 등록했을때 문자발송이 예약됩니다.',
            '등록된 예약의 예약상태를 ‘예약확정’으로 변경했을 때 문자발송이 예약됩니다.',
            '‘예약확정’ 상태로 등록된 예약의 ‘예약시작시간’을 변경했을 때 문자발송이 예약됩니다.',
            '예약시작시간이 예약등록일시 또는 예약변경일시보다 과거일 경우에는 문자발송이 예약되지 않습니다.',
            '단, 실제 문자 발송시간은 지났으나 아직 예약시작시간이 도래하지 않은 예약을 등록(수정)하면 ‘즉시’ 문자가 발송됩니다.',
            '다른 예약상태로 수정 시, 발송되지 않은 예약알림 문자는 모두 발송 취소 됩니다.',
            '예약 삭제 시, 발송되지 않은 예약알림 문자는 모두 발송 취소 됩니다.',
            '단, 발송 5분전에는 예약발송이 취소되지 않습니다.',
            '예약에 연결된 매출을 삭제해 예약상태가 ‘예약확정’으로 변경된 경우엔 문자발송이 예약되지 않습니다.',
            '문자내용을 편집할 경우 편집한 시점 이후에 예약된 문자부터 새 문자내용이 적용됩니다.',
            '문자내용을 편집해도 이미 예약된 문자에는 수정된 내용이 반영되지 않습니다. 이미 예약된 문자의 내용을 바꾸고 싶다면 문자내역에서 문자내용을 직접 편집해주세요.'
        ]
    },
    {
        type: 'sms',
        // timeType: ''
        key: AutoMessageSettingModel.AUTO_MESSAGE_KEYS.RESERVATION_CONFIRM,
        model: 'schedule',
        title: '예약 확정',
        isAd: false,
        data: {
            isFree: false,
            hasProductIds: false
        },
        content: {
            body: "#{\uACE0\uAC1D\uBA85}\uB2D8,\n#{\uC0C1\uC810\uBA85} \uC608\uC57D\uC774 \uD655\uC815\uB418\uC5C8\uC2B5\uB2C8\uB2E4.\n\n\u203B\uC608\uC57D \uC815\uBCF4\u203B\n\uC608\uC57D\uC77C\uC2DC : #{\uC608\uC57D\uC77C\uC2DC}\n\uB2F4\uB2F9\uC790 : #{\uB2F4\uB2F9\uC790\uBA85}\n\uC608\uC57D\uC0C1\uD488 : #{\uC0C1\uD488}\n\uC18C\uC694\uC2DC\uAC04 : #{\uC18C\uC694\uC2DC\uAC04}\n\n\uC608\uC57D \uBCC0\uACBD \uB610\uB294 \uCDE8\uC18C \uC2DC \uBBF8\uB9AC \uC5F0\uB77D\uBD80\uD0C1\uB4DC\uB9BD\uB2C8\uB2E4."
        },
        sendAtOptions: [
            {
                label: '확정 후 알림',
                target: 'createdAt',
                type: 'after',
                valueUnit: 'hour',
                items: getAfterSendAtOptionsItems('확정', [0, 0.16666, 0.5, 1, 2, 3]),
                sendMessageOnFailAlimtalk: true
            }
        ],
        variables: __spreadArray([
            MESSAGE_VARIABLES.RESERVATION_FEE_TIME_LIMIT,
            MESSAGE_VARIABLES.RESERVATION_DATE,
            MESSAGE_VARIABLES.RESERVATION_FEE,
            MESSAGE_VARIABLES.MANAGER_NAME,
            MESSAGE_VARIABLES.RESERVATION_PRODUCTS,
            MESSAGE_VARIABLES.RESERVATION_TIME_SPEND
        ], DEFAULT_VARIABLES, true),
        notices: [
            '예약확정 문자는 예약등록일시를 기준으로 발송됩니다.',
            '새로운 예약을 ‘예약확정’ 상태로 등록했을때 문자발송이 예약됩니다.',
            '등록된 예약의 예약상태를 ‘예약확정’으로 변경했을 때 문자발송이 예약됩니다.',
            '‘예약확정’ 상태로 등록된 예약의 ‘예약시작시간’을 변경했을 때 문자발송이 예약됩니다.',
            '예약시작시간이 예약등록일시 또는 예약변경일시보다 과거일 경우에는 문자발송이 예약되지 않습니다.',
            '다른 예약상태로 수정 시, 발송되지 않은 예약확정 문자는 모두 발송 취소 됩니다.',
            '예약 삭제 시, 발송되지 않은 예약확정 문자는 모두 발송 취소 됩니다.',
            '단, 발송 5분전에는 예약발송이 취소되지 않습니다.',
            '예약에 연결된 매출을 삭제해 예약상태가 ‘예약확정’으로 변경된 경우엔 문자발송이 예약되지 않습니다.',
            '문자내용을 편집할 경우 편집한 시점 이후에 예약된 문자부터 새 문자내용이 적용됩니다.',
            '문자내용을 편집해도 이미 예약된 문자에는 수정된 내용이 반영되지 않습니다. 이미 예약된 문자의 내용을 바꾸고 싶다면 문자내역에서 문자내용을 직접 편집해주세요.'
        ]
    },
    {
        type: 'sms',
        // timeType: ''
        key: AutoMessageSettingModel.AUTO_MESSAGE_KEYS.RESERVATION_CANCEL,
        model: 'schedule',
        title: '예약 취소',
        isAd: false,
        data: {
            isFree: false,
            hasProductIds: false
        },
        content: {
            body: "#{\uACE0\uAC1D\uBA85}\uB2D8,\n#{\uC0C1\uC810\uBA85} \uC608\uC57D\uC774 \uCDE8\uC18C\uB418\uC5C8\uC2B5\uB2C8\uB2E4.\n\n\u203B\uCDE8\uC18C\uB41C \uC608\uC57D \uC815\uBCF4\u203B\n\uC608\uC57D\uC77C\uC2DC : #{\uC608\uC57D\uC77C\uC2DC}\n\uB2F4\uB2F9\uC790 : #{\uB2F4\uB2F9\uC790\uBA85}\n\uC608\uC57D\uC0C1\uD488 : #{\uC0C1\uD488}\n\uC18C\uC694\uC2DC\uAC04 : #{\uC18C\uC694\uC2DC\uAC04} \n\n\uB2E4\uC74C\uC5D0 \uC774\uC6A9\uD574\uC8FC\uC138\uC694. \uAC10\uC0AC\uD569\uB2C8\uB2E4."
        },
        sendAtOptions: [
            {
                label: '취소 후 알림',
                target: 'deletedAt',
                type: 'after',
                valueUnit: 'hour',
                items: getAfterSendAtOptionsItems('취소', [0, 0.16666, 0.5, 1, 2, 3]),
                sendMessageOnFailAlimtalk: true
            }
        ],
        variables: __spreadArray([
            MESSAGE_VARIABLES.RESERVATION_DATE,
            MESSAGE_VARIABLES.MANAGER_NAME,
            MESSAGE_VARIABLES.RESERVATION_PRODUCTS,
            MESSAGE_VARIABLES.RESERVATION_TIME_SPEND
        ], DEFAULT_VARIABLES, true),
        notices: [
            '예약취소 문자는 예약취소일시, 예약삭제일시를 기준으로 발송됩니다.',
            '새로운 예약을 ‘예약취소’ 상태로 등록했을때 문자발송이 예약됩니다.',
            '등록된 예약의 예약상태를 ‘예약취소’로 변경했을 때 문자발송이 예약됩니다.',
            '‘예약취소’ 상태로 등록된 예약의 ‘예약시작시간’을 변경했을 때 문자발송이 예약됩니다.',
            '등록된 예약 ‘예약삭제’ 했을 때 문자발송이 예약됩니다.',
            '다른 예약상태로 수정 시, 발송되지 않은 예약취소 문자는 모두 발송 취소 됩니다.',
            '단, 발송 5분전에는 예약발송이 취소되지 않습니다.',
            '예약에 연결된 매출을 삭제해 예약상태가 ‘예약취소’로 변경된 경우엔 문자발송이 예약되지 않습니다.',
            '문자내용을 편집할 경우 편집한 시점 이후에 예약된 문자부터 새 문자내용이 적용됩니다.',
            '문자내용을 편집해도 이미 예약된 문자에는 수정된 내용이 반영되지 않습니다. 이미 예약된 문자의 내용을 바꾸고 싶다면 문자내역에서 문자내용을 직접 편집해주세요.'
        ]
    },
    {
        type: 'sms',
        // timeType: ''
        key: AutoMessageSettingModel.AUTO_MESSAGE_KEYS.RESERVATION_NOSHOW,
        model: 'schedule',
        title: '노쇼 알림',
        isAd: false,
        data: {
            isFree: false,
            hasProductIds: false
        },
        content: {
            body: "#{\uACE0\uAC1D\uBA85}\uB2D8,\n#{\uC0C1\uC810\uBA85}\uC758 \uC608\uC57D\uC2DC\uAC04\uC5D0 \uBC29\uBB38\uD558\uC9C0 \uC54A\uC544 \uC608\uC57D\uC774 \uC790\uB3D9\uCDE8\uC18C\uB418\uC5C8\uC2B5\uB2C8\uB2E4.\n\n\u203B\uCDE8\uC18C\uB41C \uC608\uC57D \uC815\uBCF4\u203B\n\uC608\uC57D\uC77C\uC2DC : #{\uC608\uC57D\uC77C\uC2DC}\n\uB2F4\uB2F9\uC790 : #{\uB2F4\uB2F9\uC790\uBA85}\n\uC608\uC57D\uC0C1\uD488 : #{\uC0C1\uD488}\n\uC18C\uC694\uC2DC\uAC04 : #{\uC18C\uC694\uC2DC\uAC04} \n\n\uB2E4\uC74C\uC5D0 \uC774\uC6A9\uD574\uC8FC\uC138\uC694. \uAC10\uC0AC\uD569\uB2C8\uB2E4."
        },
        sendAtOptions: [
            {
                label: '취소 후 알림',
                target: 'dateOption.noshownAt',
                type: 'after',
                valueUnit: 'hour',
                items: getAfterSendAtOptionsItems('노쇼', [0, 0.16666, 0.5, 1, 2, 3]),
                sendMessageOnFailAlimtalk: true
            }
        ],
        variables: __spreadArray([
            MESSAGE_VARIABLES.RESERVATION_DATE,
            MESSAGE_VARIABLES.MANAGER_NAME,
            MESSAGE_VARIABLES.RESERVATION_PRODUCTS,
            MESSAGE_VARIABLES.RESERVATION_TIME_SPEND
        ], DEFAULT_VARIABLES, true),
        notices: [
            '예약노쇼 문자는 예약노쇼일시를 기준으로 발송됩니다.',
            '새로운 예약을 ‘노쇼’ 상태로 등록했을때 문자발송이 예약됩니다.',
            '등록된 예약의 예약상태를 ‘노쇼’로 변경했을 때 문자발송이 예약됩니다.',
            '‘노쇼’상태로 등록된 예약의 ‘예약시작시간’을 변경했을 때 문자발송이 예약됩니다.',
            '예약 삭제 시, 발송되지 않은 노쇼알림 문자는 모두 발송 취소 됩니다.',
            '단, 발송 5분전에는 예약발송이 취소되지 않습니다.',
            '다른 예약상태로 수정 시, 발송되지 않은 노쇼알림 문자는 모두 발송 취소 됩니다.',
            '예약에 연결된 매출을 삭제해 예약상태가 ‘노쇼’로 변경된 경우엔 문자발송이 예약되지 않습니다.',
            '문자내용을 편집할 경우 편집한 시점 이후에 예약된 문자부터 새 문자내용이 적용됩니다.',
            '문자내용을 편집해도 이미 예약된 문자에는 수정된 내용이 반영되지 않습니다. 이미 예약된 문자의 내용을 바꾸고 싶다면 문자내역에서 문자내용을 직접 편집해주세요.'
        ]
    },
    {
        type: 'sms',
        // timeType: ''
        key: AutoMessageSettingModel.AUTO_MESSAGE_KEYS.SALES_COMPLETE,
        model: 'salesHistory',
        title: '결제',
        isAd: false,
        data: {
            isFree: false,
            hasProductIds: false
        },
        content: {
            body: "#{\uACE0\uAC1D\uBA85}\uB2D8,\n#{\uC0C1\uC810\uBA85} \uACB0\uC81C\uAC00 \uC644\uB8CC\uB418\uC5C8\uC2B5\uB2C8\uB2E4.\n\n\u203B\uAD6C\uB9E4 \uB0B4\uC5ED\u203B\n#{\uD310\uB9E4\uB0B4\uC5ED}\n\n\u203B\uACB0\uC81C \uB0B4\uC5ED\u203B\n#{\uACB0\uC81C\uB0B4\uC5ED}\n\n#{\uC815\uAE30\uAD8C\uC774\uC6A9\uC815\uBCF4}\n#{\uC120\uBD88\uC561\uC774\uC6A9\uC815\uBCF4}\n#{\uD3EC\uC778\uD2B8\uC774\uC6A9\uC815\uBCF4}\n\n\uC774\uC6A9\uD574\uC8FC\uC154\uC11C \uAC10\uC0AC\uD569\uB2C8\uB2E4."
        },
        sendAtOptions: [
            {
                label: '결제 후 알림',
                target: 'createdAt',
                type: 'after',
                valueUnit: 'hour',
                items: getAfterSendAtOptionsItems('매출', [0, 0.16666, 0.5, 1, 2, 3, 24, 48, 72, 240, 480, 720, 1440, 2160, 2880, 3600, 4320]),
                sendMessageOnFailAlimtalk: true
            }
        ],
        variables: __spreadArray([
            MESSAGE_VARIABLES.SALES_ITEMS,
            MESSAGE_VARIABLES.SALES_METHODS,
            MESSAGE_VARIABLES.SALES_TICKETS,
            MESSAGE_VARIABLES.SALES_PREPAID,
            MESSAGE_VARIABLES.SALES_PREPAID_DETAIL,
            MESSAGE_VARIABLES.SALES_POINT
        ], DEFAULT_VARIABLES, true),
        notices: [
            '결제문자는 매출등록일시를 기준으로 발송됩니다.',
            '매출 삭제 시 발송되기 전 결제 문자는 모두 발송 취소됩니다.'
        ]
    },
    {
        type: 'sms',
        // timeType: ''
        key: AutoMessageSettingModel.AUTO_MESSAGE_KEYS.SALES_CANCEL,
        model: 'salesHistory',
        title: '결제 취소',
        isAd: false,
        data: {
            isFree: false,
            hasProductIds: false
        },
        content: {
            body: "#{\uACE0\uAC1D\uBA85}\uB2D8,\n#{\uC0C1\uC810\uBA85} \uACB0\uC81C\uAC00 \uCDE8\uC18C\uB418\uC5C8\uC2B5\uB2C8\uB2E4.\n\n\u203B\uAD6C\uB9E4 \uCDE8\uC18C\uB0B4\uC5ED\u203B\n#{\uD310\uB9E4\uB0B4\uC5ED}\n\n\u203B\uACB0\uC81C \uCDE8\uC18C\uB0B4\uC5ED\u203B\n#{\uACB0\uC81C\uB0B4\uC5ED}\n\n#{\uC815\uAE30\uAD8C\uC774\uC6A9\uC815\uBCF4}\n#{\uC120\uBD88\uC561\uC774\uC6A9\uC815\uBCF4}\n#{\uD3EC\uC778\uD2B8\uC774\uC6A9\uC815\uBCF4}\n\uB2E4\uC74C\uC5D0 \uC774\uC6A9\uD574 \uC8FC\uC138\uC694, \uAC10\uC0AC\uD569\uB2C8\uB2E4."
        },
        sendAtOptions: [
            {
                label: '취소 후 알림',
                target: 'deletedAt',
                type: 'after',
                valueUnit: 'hour',
                items: getAfterSendAtOptionsItems('취소', [0, 0.16666, 0.5, 1, 2, 3, 24, 48, 72, 240, 480, 720, 1440, 2160, 2880, 3600, 4320]),
                sendMessageOnFailAlimtalk: true
            }
        ],
        variables: __spreadArray([
            MESSAGE_VARIABLES.SALES_ITEMS,
            MESSAGE_VARIABLES.SALES_METHODS,
            MESSAGE_VARIABLES.SALES_TICKETS,
            MESSAGE_VARIABLES.SALES_PREPAID,
            MESSAGE_VARIABLES.SALES_PREPAID_DETAIL,
            MESSAGE_VARIABLES.SALES_POINT
        ], DEFAULT_VARIABLES, true),
        notices: ['결제취소 문자는 매출삭제 시 발송됩니다.', '결제취소문자는 매출삭제일시를 기준으로 발송됩니다.']
    },
    {
        type: 'sms',
        // timeType: ''
        key: AutoMessageSettingModel.AUTO_MESSAGE_KEYS.SALES_PREPAID_BUY,
        model: 'salesHistory',
        title: '선불액 판매',
        isAd: false,
        data: {
            isFree: false,
            hasProductIds: false
        },
        content: {
            body: "#{\uACE0\uAC1D\uBA85}\uB2D8,\n#{\uC0C1\uC810\uBA85}\uC758 \uC120\uBD88\uC561\uC774 \uAD6C\uB9E4 \uC644\uB8CC\uB418\uC5C8\uC2B5\uB2C8\uB2E4.\n\n#{\uC120\uBD88\uC561\uC774\uC6A9\uC815\uBCF4}\n\n\uC774\uC6A9\uD574\uC8FC\uC154\uC11C \uAC10\uC0AC\uD569\uB2C8\uB2E4."
        },
        sendAtOptions: [
            {
                label: '판매 후 알림',
                target: 'createdAt',
                type: 'after',
                valueUnit: 'hour',
                items: getAfterSendAtOptionsItems('구매', [0, 0.16666, 0.5, 1, 2, 3, 24, 48, 72, 240, 480, 720, 1440, 2160, 2880, 3600, 4320]),
                sendMessageOnFailAlimtalk: true
            }
        ],
        variables: __spreadArray([
            MESSAGE_VARIABLES.SALES_METHODS,
            MESSAGE_VARIABLES.SALES_PREPAID,
            MESSAGE_VARIABLES.SALES_POINT
        ], DEFAULT_VARIABLES, true),
        notices: [
            '선불액 판매 문자는 매출등록 시 발송됩니다.',
            '선불액 판매 문자는 매출등록일시를 기준으로 발송됩니다.',
            '매출 삭제 시 발송되기 전 정기권 차감결제 문자는 모두 발송 취소됩니다.',
            '단, 발송 5분전에는 예약발송이 취소되지 않습니다.'
        ]
    },
    {
        type: 'sms',
        // timeType: ''
        key: AutoMessageSettingModel.AUTO_MESSAGE_KEYS.SALES_PREPAID_USED,
        model: 'salesHistory',
        title: '선불액 차감결제',
        isAd: false,
        data: {
            isFree: false,
            hasProductIds: false
        },
        content: {
            body: "#{\uACE0\uAC1D\uBA85}\uB2D8,\n#{\uC0C1\uC810\uBA85}\uC758 \uC120\uBD88\uC561\uC774 \uCC28\uAC10 \uC644\uB8CC\uB418\uC5C8\uC2B5\uB2C8\uB2E4.\n\n#{\uC120\uBD88\uC561\uC774\uC6A9\uC815\uBCF4}\n\n\uC774\uC6A9\uD574\uC8FC\uC154\uC11C \uAC10\uC0AC\uD569\uB2C8\uB2E4."
        },
        sendAtOptions: [
            {
                label: '차감 후 알림',
                target: 'createdAt',
                type: 'after',
                valueUnit: 'hour',
                items: getAfterSendAtOptionsItems('차감', [0, 0.16666, 0.5, 1, 2, 3, 24, 48, 72, 240, 480, 720, 1440, 2160, 2880, 3600, 4320]),
                sendMessageOnFailAlimtalk: true
            }
        ],
        variables: __spreadArray([
            MESSAGE_VARIABLES.SALES_ITEMS,
            MESSAGE_VARIABLES.SALES_METHODS,
            MESSAGE_VARIABLES.SALES_TICKETS,
            MESSAGE_VARIABLES.SALES_PREPAID,
            MESSAGE_VARIABLES.SALES_PREPAID_DETAIL,
            MESSAGE_VARIABLES.SALES_POINT,
            MESSAGE_VARIABLES.SALES_USED_PREPAID
        ], DEFAULT_VARIABLES, true),
        notices: [
            '선불액 차감결제 문자는 매출등록 시 발송됩니다.',
            '선불액 차감결제 문자는 매출등록일시를 기준으로 발송됩니다.',
            '매출 삭제 시 발송되기 전 정기권 차감결제 문자는 모두 발송 취소됩니다.',
            '단, 발송 5분전에는 예약발송이 취소되지 않습니다.'
        ]
    },
    {
        type: 'sms',
        // timeType: ''
        key: AutoMessageSettingModel.AUTO_MESSAGE_KEYS.SALES_PREPAID_BUY_CANCEL,
        model: 'salesHistory',
        title: '선불액 판매취소',
        isAd: false,
        data: {
            isFree: false,
            hasProductIds: false
        },
        content: {
            body: "#{\uACE0\uAC1D\uBA85}\uB2D8,\n#{\uC0C1\uC810\uBA85}\uC758 \uC120\uBD88\uC561\uC774 \uAD6C\uB9E4 \uCDE8\uC18C\uB418\uC5C8\uC2B5\uB2C8\uB2E4.\n\n#{\uC120\uBD88\uC561\uC774\uC6A9\uC815\uBCF4}\n\n\uC774\uC6A9\uD574\uC8FC\uC154\uC11C \uAC10\uC0AC\uD569\uB2C8\uB2E4."
        },
        sendAtOptions: [
            {
                label: '취소 후 알림',
                target: 'deletedAt',
                type: 'after',
                valueUnit: 'hour',
                items: getAfterSendAtOptionsItems('취소', [0, 0.16666, 0.5, 1, 2, 3, 24, 48, 72, 240, 480, 720, 1440, 2160, 2880, 3600, 4320]),
                sendMessageOnFailAlimtalk: true
            }
        ],
        variables: __spreadArray([
            MESSAGE_VARIABLES.SALES_METHODS,
            MESSAGE_VARIABLES.SALES_PREPAID,
            MESSAGE_VARIABLES.SALES_POINT
        ], DEFAULT_VARIABLES, true),
        notices: [
            '선불액 판매취소 문자는 매출삭제 시 발송됩니다.',
            '선불액 판매취소 문자는 매출삭제일시를 기준으로 발송됩니다.'
        ]
    },
    {
        type: 'sms',
        // timeType: ''
        key: AutoMessageSettingModel.AUTO_MESSAGE_KEYS.SALES_PREPAID_USED_CANCEL,
        model: 'salesHistory',
        title: '선불액 차감결제 취소',
        isAd: false,
        data: {
            isFree: false,
            hasProductIds: false
        },
        content: {
            body: "#{\uACE0\uAC1D\uBA85}\uB2D8,\n#{\uC0C1\uC810\uBA85}\uC758 \uC120\uBD88\uC561\uC774 \uCC28\uAC10 \uCDE8\uC18C\uB418\uC5C8\uC2B5\uB2C8\uB2E4.\n\n#{\uC120\uBD88\uC561\uC774\uC6A9\uC815\uBCF4}\n\n\uC774\uC6A9\uD574\uC8FC\uC154\uC11C \uAC10\uC0AC\uD569\uB2C8\uB2E4."
        },
        sendAtOptions: [
            {
                label: '취소 후 알림',
                target: 'deletedAt',
                type: 'after',
                valueUnit: 'hour',
                items: getAfterSendAtOptionsItems('취소', [0, 0.16666, 0.5, 1, 2, 3, 24, 48, 72, 240, 480, 720, 1440, 2160, 2880, 3600, 4320]),
                sendMessageOnFailAlimtalk: true
            }
        ],
        variables: __spreadArray([
            MESSAGE_VARIABLES.SALES_ITEMS,
            MESSAGE_VARIABLES.SALES_METHODS,
            MESSAGE_VARIABLES.SALES_TICKETS,
            MESSAGE_VARIABLES.SALES_PREPAID,
            MESSAGE_VARIABLES.SALES_PREPAID_DETAIL,
            MESSAGE_VARIABLES.SALES_POINT,
            MESSAGE_VARIABLES.SALES_USED_PREPAID
        ], DEFAULT_VARIABLES, true),
        notices: [
            '선불액 차감결제 취소 문자는 매출삭제 시 발송됩니다.',
            '선불액 차감결제 취소 문자는 매출삭제일시를 기준으로 발송됩니다.'
        ]
    },
    {
        type: 'sms',
        // timeType: ''
        key: AutoMessageSettingModel.AUTO_MESSAGE_KEYS.SALES_TICKET_BUY,
        model: 'salesHistory',
        title: '정기권 판매',
        isAd: false,
        data: {
            isFree: true,
            hasProductIds: false
        },
        content: {
            body: "#{\uACE0\uAC1D\uBA85}\uB2D8,\n#{\uC0C1\uC810\uBA85}\uC758 \uC815\uAE30\uAD8C\uC774 \uAD6C\uB9E4 \uC644\uB8CC\uB418\uC5C8\uC2B5\uB2C8\uB2E4.\n\n#{\uC815\uAE30\uAD8C\uC774\uC6A9\uC815\uBCF4}\n\n\uC774\uC6A9\uD574\uC8FC\uC154\uC11C \uAC10\uC0AC\uD569\uB2C8\uB2E4."
        },
        sendAtOptions: [
            {
                label: '판매 후 알림',
                target: 'createdAt',
                type: 'after',
                valueUnit: 'hour',
                items: getAfterSendAtOptionsItems('구매', [0, 0.16666, 0.5, 1, 2, 3, 24, 48, 72, 240, 480, 720, 1440, 2160, 2880, 3600, 4320]),
                sendMessageOnFailAlimtalk: true
            }
        ],
        variables: __spreadArray([
            MESSAGE_VARIABLES.SALES_METHODS,
            MESSAGE_VARIABLES.SALES_PREPAID,
            MESSAGE_VARIABLES.SALES_PREPAID_DETAIL,
            MESSAGE_VARIABLES.SALES_POINT
        ], DEFAULT_VARIABLES, true),
        notices: [
            '정기권 판매 문자는 매출등록 시 발송됩니다.',
            '정기권 판매 문자는 매출등록일시를 기준으로 발송됩니다.',
            '매출 삭제 시 발송되기 전 정기권 차감결제 문자는 모두 발송 취소됩니다.',
            '단, 발송 5분전에는 예약발송이 취소되지 않습니다.'
        ]
    },
    {
        type: 'sms',
        // timeType: ''
        key: AutoMessageSettingModel.AUTO_MESSAGE_KEYS.SALES_TICKET_USED,
        model: 'salesHistory',
        title: '정기권 차감결제',
        isAd: false,
        data: {
            isFree: false,
            hasProductIds: false
        },
        content: {
            body: "#{\uACE0\uAC1D\uBA85}\uB2D8,\n#{\uC0C1\uC810\uBA85}\uC758 \uC815\uAE30\uAD8C\uC774 \uCC28\uAC10 \uC644\uB8CC\uB418\uC5C8\uC2B5\uB2C8\uB2E4.\n\n#{\uC815\uAE30\uAD8C\uC774\uC6A9\uC815\uBCF4}\n\n\uC774\uC6A9\uD574\uC8FC\uC154\uC11C \uAC10\uC0AC\uD569\uB2C8\uB2E4."
        },
        sendAtOptions: [
            {
                label: '차감 후 알림',
                target: 'createdAt',
                type: 'after',
                valueUnit: 'hour',
                items: getAfterSendAtOptionsItems('차감', [0, 0.16666, 0.5, 1, 2, 3, 24, 48, 72, 240, 480, 720, 1440, 2160, 2880, 3600, 4320]),
                sendMessageOnFailAlimtalk: true
            }
        ],
        variables: __spreadArray([
            MESSAGE_VARIABLES.SALES_ITEMS,
            MESSAGE_VARIABLES.SALES_METHODS,
            MESSAGE_VARIABLES.SALES_TICKETS,
            MESSAGE_VARIABLES.SALES_PREPAID,
            MESSAGE_VARIABLES.SALES_PREPAID_DETAIL,
            MESSAGE_VARIABLES.SALES_POINT,
            MESSAGE_VARIABLES.SALES_USED_TICKET,
            MESSAGE_VARIABLES.SALES_USED_TICKET_NAME,
            MESSAGE_VARIABLES.SALES_REMAIN_TICKET
        ], DEFAULT_VARIABLES, true),
        notices: [
            '정기권 차감결제 문자는 매출등록 시 발송됩니다.',
            '정기권 차감결제 문자는 매출등록일시를 기준으로 발송됩니다.',
            '매출 삭제 시 발송되기 전 정기권 차감결제 문자는 모두 발송 취소됩니다.',
            '단, 발송 5분전에는 예약발송이 취소되지 않습니다.'
        ]
    },
    {
        type: 'sms',
        // timeType: ''
        key: AutoMessageSettingModel.AUTO_MESSAGE_KEYS.SALES_TICKET_BUY_CANCEL,
        model: 'salesHistory',
        title: '정기권 판매취소',
        isAd: false,
        data: {
            isFree: false,
            hasProductIds: false
        },
        content: {
            body: "#{\uACE0\uAC1D\uBA85}\uB2D8,\n#{\uC0C1\uC810\uBA85}\uC758 \uC815\uAE30\uAD8C\uC774 \uAD6C\uB9E4 \uCDE8\uC18C\uB418\uC5C8\uC2B5\uB2C8\uB2E4.\n\n#{\uC815\uAE30\uAD8C\uC774\uC6A9\uC815\uBCF4}\n\n\uC774\uC6A9\uD574\uC8FC\uC154\uC11C \uAC10\uC0AC\uD569\uB2C8\uB2E4."
        },
        sendAtOptions: [
            {
                label: '취소 후 알림',
                target: 'deletedAt',
                type: 'after',
                valueUnit: 'hour',
                items: getAfterSendAtOptionsItems('취소', [0, 0.16666, 0.5, 1, 2, 3, 24, 48, 72, 240, 480, 720, 1440, 2160, 2880, 3600, 4320]),
                sendMessageOnFailAlimtalk: true
            }
        ],
        variables: __spreadArray([
            MESSAGE_VARIABLES.SALES_METHODS,
            MESSAGE_VARIABLES.SALES_PREPAID,
            MESSAGE_VARIABLES.SALES_PREPAID_DETAIL,
            MESSAGE_VARIABLES.SALES_POINT
        ], DEFAULT_VARIABLES, true),
        notices: [
            '정기권 판매취소 문자는 매출삭제 시 발송됩니다.',
            '정기권 판매취소 문자는 매출삭제일시를 기준으로 발송됩니다.'
        ]
    },
    {
        type: 'sms',
        // timeType: ''
        key: AutoMessageSettingModel.AUTO_MESSAGE_KEYS.SALES_TICKET_USED_CANCEL,
        model: 'salesHistory',
        title: '정기권 차감결제 취소',
        isAd: false,
        data: {
            isFree: false,
            hasProductIds: false
        },
        content: {
            body: "#{\uACE0\uAC1D\uBA85}\uB2D8,\n#{\uC0C1\uC810\uBA85}\uC758 \uC815\uAE30\uAD8C\uC774 \uCC28\uAC10 \uCDE8\uC18C\uB418\uC5C8\uC2B5\uB2C8\uB2E4.\n\n#{\uC815\uAE30\uAD8C\uC774\uC6A9\uC815\uBCF4}\n\n\uC774\uC6A9\uD574\uC8FC\uC154\uC11C \uAC10\uC0AC\uD569\uB2C8\uB2E4."
        },
        sendAtOptions: [
            {
                label: '취소 후 알림',
                target: 'deletedAt',
                type: 'after',
                valueUnit: 'hour',
                items: getAfterSendAtOptionsItems('취소', [0, 0.16666, 0.5, 1, 2, 3, 24, 48, 72, 240, 480, 720, 1440, 2160, 2880, 3600, 4320]),
                sendMessageOnFailAlimtalk: true
            }
        ],
        variables: __spreadArray([
            MESSAGE_VARIABLES.SALES_ITEMS,
            MESSAGE_VARIABLES.SALES_METHODS,
            MESSAGE_VARIABLES.SALES_TICKETS,
            MESSAGE_VARIABLES.SALES_PREPAID,
            MESSAGE_VARIABLES.SALES_PREPAID_DETAIL,
            MESSAGE_VARIABLES.SALES_POINT,
            MESSAGE_VARIABLES.SALES_USED_TICKET,
            MESSAGE_VARIABLES.SALES_USED_TICKET_NAME,
            MESSAGE_VARIABLES.SALES_REMAIN_TICKET
        ], DEFAULT_VARIABLES, true),
        notices: [
            '정기권 차감결제 취소 문자는 매출삭제 시 발송됩니다.',
            '정기권 차감결제 취소 문자는 매출삭제일시를 기준으로 발송됩니다.'
        ]
    },
    {
        type: 'sms',
        // timeType: ''
        key: AutoMessageSettingModel.AUTO_MESSAGE_KEYS.SALES_CUSTOMER_POINT_BUY,
        model: 'salesHistory',
        title: '포인트 매출 적립',
        isAd: false,
        data: {
            isFree: false,
            hasProductIds: false
        },
        content: {
            body: "#{\uACE0\uAC1D\uBA85}\uB2D8,\n#{\uC0C1\uC810\uBA85}\uC758 \uD3EC\uC778\uD2B8\uAC00 \uC801\uB9BD\uB418\uC5C8\uC2B5\uB2C8\uB2E4.\n\n#{\uD3EC\uC778\uD2B8\uC774\uC6A9\uC815\uBCF4}\n\n\uC774\uC6A9\uD574\uC8FC\uC154\uC11C \uAC10\uC0AC\uD569\uB2C8\uB2E4."
        },
        sendAtOptions: [
            {
                label: '판매 후 알림',
                target: 'createdAt',
                type: 'after',
                valueUnit: 'hour',
                items: getAfterSendAtOptionsItems('구매', [0, 0.16666, 0.5, 1, 2, 3, 24, 48, 72, 240, 480, 720, 1440, 2160, 2880, 3600, 4320]),
                sendMessageOnFailAlimtalk: true
            }
        ],
        variables: __spreadArray([
            MESSAGE_VARIABLES.SALES_METHODS,
            MESSAGE_VARIABLES.SALES_PREPAID,
            MESSAGE_VARIABLES.SALES_POINT
        ], DEFAULT_VARIABLES, true),
        notices: [
            '포인트 매출 적립 문자는 매출등록 시 발송됩니다.',
            '포인트 매출 적립 문자는 매출등록일시를 기준으로 발송됩니다.',
            '매출 삭제 시 발송되기 전 포인트 매출 적립 문자는 모두 발송 취소됩니다.',
            '단, 발송 5분전에는 예약발송이 취소되지 않습니다.'
        ]
    },
    {
        type: 'sms',
        // timeType: ''
        key: AutoMessageSettingModel.AUTO_MESSAGE_KEYS.SALES_CUSTOMER_POINT_USED,
        model: 'salesHistory',
        title: '포인트 차감결제',
        isAd: false,
        data: {
            isFree: false,
            hasProductIds: false
        },
        content: {
            body: "#{\uACE0\uAC1D\uBA85}\uB2D8,\n#{\uC0C1\uC810\uBA85} \uD3EC\uC778\uD2B8\uAC00 \uCC28\uAC10\uB418\uC5C8\uC2B5\uB2C8\uB2E4.\n\n#{\uD3EC\uC778\uD2B8\uC774\uC6A9\uC815\uBCF4}\n\n\uC774\uC6A9\uD574\uC8FC\uC154\uC11C \uAC10\uC0AC\uD569\uB2C8\uB2E4."
        },
        sendAtOptions: [
            {
                label: '차감 후 알림',
                target: 'createdAt',
                type: 'after',
                valueUnit: 'hour',
                items: getAfterSendAtOptionsItems('차감', [0, 0.16666, 0.5, 1, 2, 3, 24, 48, 72, 240, 480, 720, 1440, 2160, 2880, 3600, 4320]),
                sendMessageOnFailAlimtalk: true
            }
        ],
        variables: __spreadArray([
            MESSAGE_VARIABLES.SALES_ITEMS,
            MESSAGE_VARIABLES.SALES_METHODS,
            MESSAGE_VARIABLES.SALES_TICKETS,
            MESSAGE_VARIABLES.SALES_PREPAID,
            MESSAGE_VARIABLES.SALES_POINT
        ], DEFAULT_VARIABLES, true),
        notices: [
            '포인트 차감결제 문자는 매출등록 시 발송됩니다.',
            '포인트 차감결제 문자는 매출등록일시를 기준으로 발송됩니다.',
            '매출 삭제 시 발송되기 전 포인트 매출 차감결제 문자는 모두 발송 취소됩니다.',
            '단, 발송 5분전에는 예약발송이 취소되지 않습니다.'
        ]
    },
    {
        type: 'sms',
        // timeType: ''
        key: AutoMessageSettingModel.AUTO_MESSAGE_KEYS.SALES_CUSTOMER_POINT_CANCEL,
        model: 'salesHistory',
        title: '포인트 매출적립취소',
        isAd: false,
        data: {
            isFree: false,
            hasProductIds: false
        },
        content: {
            body: "#{\uACE0\uAC1D\uBA85}\uB2D8,\n#{\uC0C1\uC810\uBA85} \uD3EC\uC778\uD2B8\uAC00 \uC801\uB9BD \uCDE8\uC18C\uB418\uC5C8\uC2B5\uB2C8\uB2E4.\n\n#{\uD3EC\uC778\uD2B8\uC774\uC6A9\uC815\uBCF4}\n\n\uC774\uC6A9\uD574\uC8FC\uC154\uC11C \uAC10\uC0AC\uD569\uB2C8\uB2E4."
        },
        sendAtOptions: [
            {
                label: '취소 후 알림',
                target: 'deletedAt',
                type: 'after',
                valueUnit: 'hour',
                items: getAfterSendAtOptionsItems('취소', [0, 0.16666, 0.5, 1, 2, 3, 24, 48, 72, 240, 480, 720, 1440, 2160, 2880, 3600, 4320]),
                sendMessageOnFailAlimtalk: true
            }
        ],
        variables: __spreadArray([
            MESSAGE_VARIABLES.SALES_METHODS,
            MESSAGE_VARIABLES.SALES_PREPAID,
            MESSAGE_VARIABLES.SALES_POINT
        ], DEFAULT_VARIABLES, true),
        notices: [
            '포인트 매출적립취소 문자는 매출삭제 시 발송됩니다.',
            '포인트 매출적립취소 문자는 매출삭제일시를 기준으로 발송됩니다.',
            '단, 발송 5분전에는 예약발송이 취소되지 않습니다.'
        ]
    },
    {
        type: 'sms',
        // timeType: ''
        key: AutoMessageSettingModel.AUTO_MESSAGE_KEYS.SALES_CUSTOMER_POINT_USED_CANCEL,
        model: 'salesHistory',
        title: '포인트 차감결제 취소',
        isAd: false,
        data: {
            isFree: false,
            hasProductIds: false
        },
        content: {
            body: "#{\uACE0\uAC1D\uBA85}\uB2D8,\n#{\uC0C1\uC810\uBA85} \uD3EC\uC778\uD2B8\uAC00 \uCC28\uAC10 \uCDE8\uC18C\uB418\uC5C8\uC2B5\uB2C8\uB2E4.\n\n#{\uD3EC\uC778\uD2B8\uC774\uC6A9\uC815\uBCF4}\n\n\uC774\uC6A9\uD574\uC8FC\uC154\uC11C \uAC10\uC0AC\uD569\uB2C8\uB2E4."
        },
        sendAtOptions: [
            {
                label: '취소 후 알림',
                target: 'deletedAt',
                type: 'after',
                valueUnit: 'hour',
                items: getAfterSendAtOptionsItems('취소', [0, 0.16666, 0.5, 1, 2, 3, 24, 48, 72, 240, 480, 720, 1440, 2160, 2880, 3600, 4320]),
                sendMessageOnFailAlimtalk: true
            }
        ],
        variables: __spreadArray([
            MESSAGE_VARIABLES.SALES_ITEMS,
            MESSAGE_VARIABLES.SALES_METHODS,
            MESSAGE_VARIABLES.SALES_TICKETS,
            MESSAGE_VARIABLES.SALES_PREPAID,
            MESSAGE_VARIABLES.SALES_POINT
        ], DEFAULT_VARIABLES, true)
    },
    {
        type: 'sms',
        // timeType: ''
        key: AutoMessageSettingModel.AUTO_MESSAGE_KEYS.TICKET_PREPAID_MODIFIED,
        model: 'ticketHistory',
        title: '선불액 임의조정',
        isAd: false,
        data: {
            isFree: false,
            hasProductIds: false
        },
        content: {
            body: "#{\uACE0\uAC1D\uBA85}\uB2D8,\n#{\uC0C1\uC810\uBA85}\uC758 \uC120\uBD88\uC561\uC774 \uC784\uC758\uC870\uC815\uB418\uC5C8\uC2B5\uB2C8\uB2E4.\n\n#{\uC120\uBD88\uC561\uC774\uC6A9\uC815\uBCF4}\n\n\uC774\uC6A9\uD574\uC8FC\uC154\uC11C \uAC10\uC0AC\uD569\uB2C8\uB2E4."
        },
        sendAtOptions: [
            {
                label: '수정 후 알림',
                target: 'createdAt',
                type: 'after',
                valueUnit: 'hour',
                items: getAfterSendAtOptionsItems('수정', [0, 0.16666, 0.5, 1, 2, 3, 24, 48, 72, 240, 480, 720, 1440, 2160, 2880, 3600, 4320]),
                sendMessageOnFailAlimtalk: true
            }
        ],
        variables: __spreadArray([MESSAGE_VARIABLES.SALES_PREPAID, MESSAGE_VARIABLES.SALES_USED_PREPAID], DEFAULT_VARIABLES, true),
        notices: ['선불액 임의조정 알림 문자는 선불액 임의조정 시점에 발송예약됩니다.']
    },
    {
        type: 'sms',
        // timeType: ''
        key: AutoMessageSettingModel.AUTO_MESSAGE_KEYS.TICKET_OTHERS_MODIFIED,
        model: 'ticketHistory',
        title: '정기권 임의조정',
        isAd: false,
        data: {
            isFree: false,
            hasProductIds: false
        },
        content: {
            body: "#{\uACE0\uAC1D\uBA85}\uB2D8,\n#{\uC0C1\uC810\uBA85}\uC758 \uC815\uAE30\uAD8C\uC774 \uC784\uC758\uC870\uC815\uB418\uC5C8\uC2B5\uB2C8\uB2E4.\n\n#{\uC815\uAE30\uAD8C\uC774\uC6A9\uC815\uBCF4}\n\n\uC774\uC6A9\uD574\uC8FC\uC154\uC11C \uAC10\uC0AC\uD569\uB2C8\uB2E4."
        },
        sendAtOptions: [
            {
                label: '수정 후 알림',
                target: 'createdAt',
                type: 'after',
                valueUnit: 'hour',
                items: getAfterSendAtOptionsItems('수정', [0, 0.16666, 0.5, 1, 2, 3, 24, 48, 72, 240, 480, 720, 1440, 2160, 2880, 3600, 4320]),
                sendMessageOnFailAlimtalk: true
            }
        ],
        variables: __spreadArray([
            MESSAGE_VARIABLES.SALES_TICKETS,
            MESSAGE_VARIABLES.SALES_USED_TICKET,
            MESSAGE_VARIABLES.SALES_USED_TICKET_NAME,
            MESSAGE_VARIABLES.SALES_REMAIN_TICKET
        ], DEFAULT_VARIABLES, true),
        notices: ['정기권 임의조정 알림 문자는 정기권 임의조정 시점에 발송예약됩니다.']
    },
    {
        type: 'sms',
        // timeType: ''
        key: AutoMessageSettingModel.AUTO_MESSAGE_KEYS.CUSTOMER_POINT_MODIFIED,
        model: 'customerPoint',
        title: '포인트 임의조정',
        isAd: false,
        data: {
            isFree: false,
            hasProductIds: false
        },
        content: {
            body: "#{\uACE0\uAC1D\uBA85}\uB2D8,\n#{\uC0C1\uC810\uBA85} \uD3EC\uC778\uD2B8\uAC00 \uC784\uC758\uC870\uC815 \uB418\uC5C8\uC2B5\uB2C8\uB2E4.\n\n#{\uD3EC\uC778\uD2B8\uC774\uC6A9\uC815\uBCF4}\n\n\uC774\uC6A9\uD574 \uC8FC\uC154\uC11C \uAC10\uC0AC\uD569\uB2C8\uB2E4."
        },
        sendAtOptions: [
            {
                label: '조정 후 알림',
                target: 'createdAt',
                type: 'after',
                valueUnit: 'hour',
                items: getAfterSendAtOptionsItems('조정', [0, 0.16666, 0.5, 1, 2, 3, 24, 48, 72, 240, 480, 720, 1440, 2160, 2880, 3600, 4320]),
                sendMessageOnFailAlimtalk: true
            }
        ],
        variables: __spreadArray([MESSAGE_VARIABLES.SALES_POINT], DEFAULT_VARIABLES, true),
        notices: ['포인트 임의조정 알림 문자는 포인트 임의조정 시점에 발송예약됩니다.']
    },
    {
        type: 'sms',
        // timeType: ''
        key: AutoMessageSettingModel.AUTO_MESSAGE_KEYS.CUSTOMER_POINT_EXPIRED,
        model: 'customerPoint',
        title: '포인트 기간만료',
        isAd: true,
        data: {
            isFree: false,
            hasProductIds: false
        },
        content: {
            body: "#{\uACE0\uAC1D\uBA85}\uB2D8,\n#{\uC0C1\uC810\uBA85}\uC758 \uD3EC\uC778\uD2B8\uAC00 \uACE7 \uB9CC\uB8CC\uB420 \uC608\uC815\uC785\uB2C8\uB2E4.\n\n#{\uB9CC\uB8CC\uC608\uC815\uD3EC\uC778\uD2B8\uC815\uBCF4}\n\n\uAE30\uAC04 \uB0B4 \uBC29\uBB38\uD558\uC154\uC11C \uC774\uC6A9\uD574 \uC8FC\uC138\uC694. \uAC10\uC0AC\uD569\uB2C8\uB2E4."
        },
        sendAtOptions: [
            {
                label: '일시지정 알림',
                target: 'expiredAt',
                type: 'fixed-before',
                valueUnit: 'day',
                items: days.map(function (_, value) { return ({
                    label: value === 0 ? "\uB9CC\uB8CC \uB2F9\uC77C" : "\uB9CC\uB8CC ".concat(value, "\uC77C \uC804"),
                    value: value
                }); }),
                sendMessageOnFailAlimtalk: true
            }
        ],
        variables: __spreadArray([MESSAGE_VARIABLES.WILL_EXPIRED_CUSTOMER_POINT], DEFAULT_VARIABLES, true),
        notices: ['포인트 기간만료 알림 문자는 해당일의 매일 00:00에 발송예약됩니다.']
    },
    {
        type: 'sms',
        // timeType: ''
        key: AutoMessageSettingModel.AUTO_MESSAGE_KEYS.TICKET_OTHERS_EXPIRED,
        model: 'ticket',
        title: '정기권 기간만료',
        isAd: false,
        data: {
            isFree: false,
            hasProductIds: false
        },
        content: {
            body: "#{\uACE0\uAC1D\uBA85}\uB2D8,\n#{\uC0C1\uC810\uBA85}\uC758 \uC815\uAE30\uAD8C\uC774 \uACE7 \uB9CC\uB8CC\uB420 \uC608\uC815\uC785\uB2C8\uB2E4..\n\n#{\uB9CC\uB8CC\uC608\uC815\uC815\uAE30\uAD8C\uC815\uBCF4}\n\n\uAE30\uAC04 \uB0B4 \uBC29\uBB38\uD558\uC154\uC11C \uC774\uC6A9\uD574 \uC8FC\uC138\uC694. \uAC10\uC0AC\uD569\uB2C8\uB2E4."
        },
        sendAtOptions: [
            {
                label: '일시지정 알림',
                target: 'expiredAt',
                type: 'fixed-before',
                valueUnit: 'day',
                items: days.map(function (_, value) { return ({
                    label: value === 0 ? "\uB9CC\uB8CC \uB2F9\uC77C" : "\uB9CC\uB8CC ".concat(value, "\uC77C \uC804"),
                    value: value
                }); }),
                sendMessageOnFailAlimtalk: true
            }
        ],
        variables: __spreadArray([MESSAGE_VARIABLES.WILL_EXPIRED_OTHER_TICKET], DEFAULT_VARIABLES, true),
        notices: ['정기권이 기간만료 알림 문자는 해당일의 매일 00:00에 발송예약됩니다.']
    },
    {
        type: 'sms',
        // timeType: ''
        key: AutoMessageSettingModel.AUTO_MESSAGE_KEYS.TICKET_PREPAID_EXPIRED,
        model: 'ticket',
        title: '선불액 기간만료',
        isAd: false,
        data: {
            isFree: false,
            hasProductIds: false
        },
        content: {
            body: "#{\uACE0\uAC1D\uBA85}\uB2D8,\n#{\uC0C1\uC810\uBA85}\uC758 \uC120\uBD88\uC561\uC774 \uACE7 \uB9CC\uB8CC\uB420 \uC608\uC815\uC785\uB2C8\uB2E4.\n\n#{\uB9CC\uB8CC\uC608\uC815\uC120\uBD88\uC561\uC815\uBCF4}\n\n\uAE30\uAC04 \uB0B4 \uBC29\uBB38\uD558\uC154\uC11C \uC774\uC6A9\uD574 \uC8FC\uC138\uC694. \uAC10\uC0AC\uD569\uB2C8\uB2E4."
        },
        sendAtOptions: [
            {
                label: '일시지정 알림',
                target: 'expiredAt',
                type: 'fixed-before',
                valueUnit: 'day',
                items: days.map(function (_, value) { return ({
                    label: value === 0 ? "\uB9CC\uB8CC \uB2F9\uC77C" : "\uB9CC\uB8CC ".concat(value, "\uC77C \uC804"),
                    value: value
                }); }),
                sendMessageOnFailAlimtalk: true
            }
        ],
        variables: __spreadArray([MESSAGE_VARIABLES.WILL_EXPIRED_PREPAID_TICKET], DEFAULT_VARIABLES, true),
        notices: ['선불액 기간만료 알림 문자는 해당일의 매일 00:00에 발송예약됩니다.']
    },
    {
        type: 'sms',
        // timeType: ''
        key: AutoMessageSettingModel.AUTO_MESSAGE_KEYS.CUSTOMER_BIRTH,
        model: 'customer',
        title: '생일고객 관리',
        isAd: true,
        data: {
            isFree: false,
            hasProductIds: false
        },
        content: {
            body: "#{\uACE0\uAC1D\uBA85}\uB2D8 \uC0DD\uC77C\uC744 \uC9C4\uC2EC\uC73C\uB85C \uCD95\uD558\uB4DC\uB9BD\uB2C8\uB2E4.\n\n\u203B\uC0DD\uC77C\uAE30\uB150 \uD560\uC778\uC774\uBCA4\uD2B8\u203B\n\uC0C1\uC810 : #{\uC0C1\uC810\uBA85}\n\uD560\uC778 : 10,000\uC6D0\n\uAE30\uAC04 : \uC0DD\uC77C \uC804\uD6C4 \uC77C\uC8FC\uC77C\n\n\uBBF8\uB9AC \uC608\uC57D\uD558\uACE0 \uBC29\uBB38\uD574\uC8FC\uC138\uC694. \uAC10\uC0AC\uD569\uB2C8\uB2E4."
        },
        sendAtOptions: [
            {
                label: '일시지정 알림',
                target: 'birth',
                type: 'fixed-before',
                valueUnit: 'day',
                items: days.map(function (_, value) { return ({
                    label: value === 0 ? "\uC0DD\uC77C \uB2F9\uC77C" : "\uC0DD\uC77C ".concat(value, "\uC77C \uC804"),
                    value: value
                }); }),
                sendMessageOnFailAlimtalk: true
            }
        ],
        variables: __spreadArray([], DEFAULT_VARIABLES, true),
        notices: ['생일고객 알림 문자는 해당일의 매일 00:00에 발송예약됩니다.']
    },
    {
        type: 'sms',
        // timeType: ''
        key: AutoMessageSettingModel.AUTO_MESSAGE_KEYS.CUSTOMER_INACTIVE,
        model: 'customer',
        title: '휴면고객 관리',
        isAd: true,
        data: {
            isFree: false,
            hasProductIds: false
        },
        content: {
            body: "#{\uACE0\uAC1D\uBA85}\uB2D8 \uADF8\uB9AC\uC6CC\uC694. \uB2E4\uC2DC \uBC29\uBB38\uD574 \uC8FC\uC2E4\uAC70\uC8E0?\n\n\u203B\uC7AC\uBC29\uBB38 \uC774\uBCA4\uD2B8\u203B\n\uC0C1\uC810 : #{\uC0C1\uC810\uBA85}\n\uD560\uC778 : 10,000\uC6D0\n\uAE30\uAC04 : \uBB38\uC790 \uC218\uC2E0 \uD6C4 \uC77C\uC8FC\uC77C\uAC04\n\n\uBBF8\uB9AC \uC608\uC57D\uD558\uACE0 \uBC29\uBB38\uD574\uC8FC\uC138\uC694. \uAC10\uC0AC\uD569\uB2C8\uB2E4."
        },
        sendAtOptions: [
            {
                label: '일시지정 알림',
                target: 'meta.lastVisitedAt',
                type: 'fixed-before',
                valueUnit: 'day',
                items: days.map(function (_, value) { return ({
                    label: value === 0 ? "\uD734\uBA74\uC9C0\uC815 \uB2F9\uC77C" : "\uD734\uBA74\uC9C0\uC815 ".concat(value, "\uC77C \uC804"),
                    value: value
                }); }),
                sendMessageOnFailAlimtalk: true
            }
        ],
        variables: DEFAULT_VARIABLES,
        notices: ['휴면고객 알림 문자는 해당일의 매일 00:00에 발송예약됩니다.']
    },
    {
        type: 'sms',
        // timeType: ''
        key: AUTO_MESSAGE_KEYS.SALES_COMPLETE_AFTER,
        model: 'salesHistory',
        title: '시술 후 안내',
        isAd: false,
        data: {
            isFree: false,
            hasProductIds: true
        },
        content: {
            body: "#{\uACE0\uAC1D\uBA85}\uB2D8,\n#{\uC0C1\uC810\uBA85}\uC5D0\uC11C \uBC1B\uC73C\uC2E0 #{\uC2DC\uC220\uC0C1\uD488} \uB9AC\uD130\uCE58 \uAE30\uAC04\uC774 \uB418\uC5B4 \uBB38\uC790\uB4DC\uB9BD\uB2C8\uB2E4.\n\uBBF8\uB9AC \uC608\uC57D\uD558\uACE0 \uBC29\uBB38\uD574\uC8FC\uC138\uC694. \uAC10\uC0AC\uD569\uB2C8\uB2E4."
        },
        sendAtOptions: [
            {
                label: '일시지정 알림',
                target: 'paidAt',
                type: 'fixed-after',
                valueUnit: 'day',
                items: days.map(function (_, value) { return ({
                    label: value === 0 ? "\uC2DC\uC220 \uB2F9\uC77C" : "\uC2DC\uC220 ".concat(value, "\uC77C \uD6C4"),
                    value: value
                }); }),
                sendMessageOnFailAlimtalk: true
            },
            {
                label: '시술 후 알림',
                target: 'createdAt',
                type: 'after',
                valueUnit: 'hour',
                items: getAfterSendAtOptionsItems('시술', [0, 0.16666, 0.5, 1, 2, 3, 24, 48, 72, 240, 480, 720, 1440, 2160, 2880, 3600, 4320]),
                sendMessageOnFailAlimtalk: true
            }
        ],
        variables: __spreadArray([MESSAGE_VARIABLES.SALES_USING_PRODUCTS], DEFAULT_VARIABLES, true),
        notices: [
            '시술 후 안내 문자는 매출등록 시 발송됩니다.',
            '시술 후 안내 문자는 매출일시를 기준으로 발송됩니다.',
            '매출 삭제 시 발송되기 전 시술 후 안내 문자는 모두 발송 취소됩니다.',
            '단, 발송 5분전에는 예약발송이 취소되지 않습니다.'
        ]
    },
    {
        type: 'sms',
        // timeType: ''
        key: AutoMessageSettingModel.AUTO_MESSAGE_KEYS.SALES_REQUEST_REPEAT_VISIT,
        model: 'salesHistory',
        title: '재방문 유도',
        isAd: true,
        data: {
            isFree: false,
            hasProductIds: true
        },
        content: {
            body: "#{\uACE0\uAC1D\uBA85}\uB2D8, #{\uC2DC\uC220\uC0C1\uD488} \uAD00\uB9AC \uC8FC\uAE30\uAC00 \uB418\uC5C8\uC5B4\uC694. \uB2E4\uC2DC \uC608\uC57D\uD558\uACE0 \uBC29\uBB38\uD574\uC8FC\uC138\uC694. \uAC10\uC0AC\uD569\uB2C8\uB2E4."
        },
        sendAtOptions: [
            {
                label: '일시지정 알림',
                target: 'expiredAt',
                type: 'fixed-after',
                valueUnit: 'day',
                items: days.map(function (_, value) { return ({
                    label: value === 0 ? "\uC2DC\uC220 \uB2F9\uC77C" : "\uC2DC\uC220 ".concat(value, "\uC77C \uD6C4"),
                    value: value
                }); }),
                sendMessageOnFailAlimtalk: true
            }
        ],
        variables: __spreadArray([MESSAGE_VARIABLES.SALES_USING_PRODUCTS], DEFAULT_VARIABLES, true),
        notices: [
            '재방문 유도 문자는 발송일 00:00에 발송예약됩니다.',
            '문자가 발송되기 전 상품매출이 추가적으로 발생할 경우, 문자발송이 취소되며 최근 상품매출일을 기준으로 다시 문자가 예약 발송됩니다.'
        ]
    },
    {
        type: 'sms',
        // timeType: ''
        key: AutoMessageSettingModel.AUTO_MESSAGE_KEYS.SHARE_TICKET_USE,
        model: 'salesHistory',
        title: '공유 차감결제',
        isAd: false,
        data: {
            isFree: false,
            hasProductIds: false
        },
        content: {
            body: "#{\uACF5\uC720\uD55C\uACE0\uAC1D\uBA85}\uB2D8\uC758 \uACF5\uC720 \uC120\uBD88\uC561\u00B7\uC815\uAE30\uAD8C\uC774 \uCC28\uAC10 \uC644\uB8CC\uB418\uC5C8\uC2B5\uB2C8\uB2E4.\n\n#{\uACF5\uC720\uC0AC\uC6A9\uC815\uBCF4}"
        },
        sendAtOptions: [
            {
                label: '차감 후 알림',
                target: 'createdAt',
                type: 'after',
                valueUnit: 'hour',
                items: getAfterSendAtOptionsItems('차감', [0, 0.16666, 0.5, 1, 2, 3, 24, 48, 72, 240, 480, 720, 1440, 2160, 2880, 3600, 4320]),
                sendMessageOnFailAlimtalk: true
            }
        ],
        variables: [
            MESSAGE_VARIABLES.TICKET_SHARED_BY,
            MESSAGE_VARIABLES.TICKET_SHARED_DESCRIPTION,
            MESSAGE_VARIABLES.TICKET_RECEIVED_BY,
            // store
            MESSAGE_VARIABLES.STORE_NAME,
            MESSAGE_VARIABLES.INSTA_URL,
            MESSAGE_VARIABLES.ACCOUNT_NUMBER
        ],
        notices: [
            '공유 차감결제 문자는 매출등록 시 발송됩니다.',
            '공유 차감결제 문자는 매출등록일시를 기준으로 발송됩니다.',
            '매출 삭제 시 발송되기 전 공유 차감결제 문자는 모두 발송 취소됩니다.',
            '단, 발송 5분전에는 예약발송이 취소되지 않습니다.'
        ]
    },
    {
        type: 'sms',
        // timeType: ''
        key: AutoMessageSettingModel.AUTO_MESSAGE_KEYS.SHARE_TICKET_USE_CANCEL,
        model: 'salesHistory',
        title: '공유 차감결제 취소',
        isAd: false,
        data: {
            isFree: false,
            hasProductIds: false
        },
        content: {
            body: "#{\uACF5\uC720\uD55C\uACE0\uAC1D\uBA85}\uB2D8\uC758 \uACF5\uC720 \uC120\uBD88\uC561\u00B7\uC815\uAE30\uAD8C\uC774 \uCC28\uAC10 \uCDE8\uC18C\uB418\uC5C8\uC2B5\uB2C8\uB2E4.\n\n#{\uACF5\uC720\uC0AC\uC6A9\uC815\uBCF4}"
        },
        sendAtOptions: [
            {
                label: '취소 후 알림',
                target: 'deletedAt',
                type: 'after',
                valueUnit: 'hour',
                items: getAfterSendAtOptionsItems('취소', [0, 0.16666, 0.5, 1, 2, 3, 24, 48, 72, 240, 480, 720, 1440, 2160, 2880, 3600, 4320]),
                sendMessageOnFailAlimtalk: true
            }
        ],
        variables: [
            MESSAGE_VARIABLES.TICKET_SHARED_BY,
            MESSAGE_VARIABLES.TICKET_SHARED_DESCRIPTION,
            MESSAGE_VARIABLES.TICKET_RECEIVED_BY,
            // store
            MESSAGE_VARIABLES.STORE_NAME,
            MESSAGE_VARIABLES.INSTA_URL,
            MESSAGE_VARIABLES.ACCOUNT_NUMBER
        ],
        notices: [
            '공유 차감결제 취소 문자는 매출삭제 시 발송됩니다.',
            '공유 차감결제 취소 문자는 매출삭제일시를 기준으로 발송됩니다.'
        ]
    }
];
