var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useMutation, useQuery } from 'react-query';
import { LambdaClient, deepConvertKeysToCamelCase, isArray, sortBy } from 'renderer/utils';
import { QUERY_CACHE } from 'renderer/queries';
import dayjs from 'dayjs';
import { ScheduleModel, StoreModel } from 'renderer/models';
export var useMappingItems = function (config) {
    return useMutation(function (payload) {
        return LambdaClient.getInstance()
            .post("/naver/items", payload, { timeout: 60000 })
            .then(function (result) { return new StoreModel(result.data); });
    }, __assign(__assign({}, config), { onSuccess: function () {
            QUERY_CACHE.invalidateQueries(['naverItemsWithOptions']);
            QUERY_CACHE.invalidateQueries(['store']);
        } }));
};
export var useNaverItemsWithOptions = function (params, config) {
    return useQuery(['naverItemsWithOptions', params], function (params) {
        var queryKey = params.queryKey;
        return LambdaClient.getInstance()
            .get("/naver/bizItems?storeId=".concat(queryKey[1].storeId))
            .then(function (result) { return result.data; });
    }, __assign(__assign({}, config), { staleTime: 60000 }));
};
export var useNaverBusinessById = function (params, config) {
    return useQuery(['naverBusiness', params], function (params) {
        var queryKey = params.queryKey;
        return LambdaClient.getInstance()
            .get("/naver/business/".concat(queryKey[1].businessId))
            .then(function (result) { return result.data; });
    }, __assign(__assign({}, config), { staleTime: 60000 }));
};
export var useUpdateNaverBusiness = function (config) {
    return useMutation(function (payload) {
        var bookingBusinessId = payload.bookingBusinessId, rest = __rest(payload, ["bookingBusinessId"]);
        return LambdaClient.getInstance()
            .put("/naver/business/".concat(bookingBusinessId), rest)
            .then(function (result) { return result.data; });
    }, __assign(__assign({}, config), { onSuccess: function () {
            QUERY_CACHE.invalidateQueries(['naverBusiness']);
            QUERY_CACHE.invalidateQueries(['store']);
        } }));
};
export var usePatchPayment = function (config) {
    return useMutation(function (payload) {
        var storeId = payload.storeId, bizItemId = payload.bizItemId, bookingId = payload.bookingId, paymentId = payload.paymentId, rest = __rest(payload, ["storeId", "bizItemId", "bookingId", "paymentId"]);
        return LambdaClient.getInstance()
            .put("/naver/".concat(storeId, "/bizItems/").concat(bizItemId, "/bookings/").concat(bookingId, "/payments/").concat(paymentId), rest)
            .then(function (result) { return deepConvertKeysToCamelCase(result.data); });
    }, __assign(__assign({}, config), { onSuccess: function (res) {
            var scheduleQueries = QUERY_CACHE.getQueriesData(['schedules']);
            scheduleQueries.map(function (query) {
                var _a, _b;
                var queryKey = query[0];
                var params = queryKey && queryKey[1];
                if (params && params.startedAt && params.endedAt) {
                    var startedAt = dayjs(params.startedAt);
                    var endedAt = dayjs(params.endedAt);
                    if (dayjs((_a = res.dateOption) === null || _a === void 0 ? void 0 : _a.startedAt).isBetween(startedAt, endedAt) ||
                        dayjs((_b = res.dateOption) === null || _b === void 0 ? void 0 : _b.endedAt).isBetween(startedAt, endedAt)) {
                        var prevSchedules = query[1];
                        if (isArray(prevSchedules)) {
                            var newSchedules = prevSchedules.filter(function (item) { return item.id !== res.id; }).concat(new ScheduleModel(res));
                            var realNewSchedueles = sortBy(newSchedules, 'dateOption.startedAt').map(function (item) { return new ScheduleModel(item); });
                            QUERY_CACHE.setQueryData(['schedules', params], realNewSchedueles);
                        }
                    }
                }
            });
        } }));
};
export var useBizItem = function (params, config) {
    return useQuery(['naverBizItem', params], function (params) {
        var queryKey = params.queryKey;
        return LambdaClient.getInstance()
            .get("/naver/business/".concat(queryKey[1].businessId, "/bizItems/").concat(queryKey[1].bizItemId))
            .then(function (result) { return result.data; });
    }, __assign(__assign({}, config), { staleTime: 60000 }));
};
export var useNaverSchedules = function (params, config) {
    return useQuery(['naverSchedules', params], function (params) {
        var queryKey = params.queryKey;
        return LambdaClient.getInstance()
            .get("/naver/business/".concat(queryKey[1].businessId, "/bizItems/").concat(queryKey[1].bizItemId, "/schedules"))
            .then(function (result) { return result.data; });
    }, __assign(__assign({}, config), { staleTime: 60000 }));
};
export var useUpdateNaverBizItem = function (config) {
    return useMutation(function (payload) {
        var businessId = payload.businessId, bizItemId = payload.bizItemId, rest = __rest(payload, ["businessId", "bizItemId"]);
        return LambdaClient.getInstance()
            .put("/naver/business/".concat(businessId, "/bizItems/").concat(bizItemId), rest)
            .then(function (result) { return result.data; });
    }, __assign(__assign({}, config), { onSuccess: function () {
            QUERY_CACHE.invalidateQueries(['naverBusiness']);
            QUERY_CACHE.invalidateQueries(['naverItemsWithOptions']);
        } }));
};
export var useDeleteNaverBizItem = function (config) {
    return useMutation(function (payload) {
        var businessId = payload.businessId, bizItemId = payload.bizItemId;
        return LambdaClient.getInstance()
            .remove("/naver/business/".concat(businessId, "/bizItems/").concat(bizItemId))
            .then(function (result) { return result.data; });
    }, __assign(__assign({}, config), { onSuccess: function () {
            QUERY_CACHE.invalidateQueries(['naverBusiness']);
            QUERY_CACHE.invalidateQueries(['naverItemsWithOptions']);
        } }));
};
export var useCreateNaverOption = function (config) {
    return useMutation(function (payload) {
        var businessId = payload.businessId, optionId = payload.optionId, rest = __rest(payload, ["businessId", "optionId"]);
        return LambdaClient.getInstance()
            .post("/naver/business/".concat(businessId, "/options"), rest)
            .then(function (result) { return result.data; });
    }, __assign(__assign({}, config), { onSuccess: function () {
            QUERY_CACHE.invalidateQueries(['naverBusiness']);
            QUERY_CACHE.invalidateQueries(['naverItemsWithOptions']);
        } }));
};
export var useUpdateNaverOption = function (config) {
    return useMutation(function (payload) {
        var businessId = payload.businessId, optionId = payload.optionId, rest = __rest(payload, ["businessId", "optionId"]);
        return LambdaClient.getInstance()
            .put("/naver/business/".concat(businessId, "/options/").concat(optionId), rest)
            .then(function (result) { return result.data; });
    }, __assign(__assign({}, config), { onSuccess: function () {
            QUERY_CACHE.invalidateQueries(['naverBusiness']);
            QUERY_CACHE.invalidateQueries(['naverItemsWithOptions']);
        } }));
};
export var useDeleteNaverOption = function (config) {
    return useMutation(function (payload) {
        var businessId = payload.businessId, optionId = payload.optionId;
        return LambdaClient.getInstance()
            .remove("/naver/business/".concat(businessId, "/options/").concat(optionId))
            .then(function (result) { return result.data; });
    }, __assign(__assign({}, config), { onSuccess: function () {
            QUERY_CACHE.invalidateQueries(['naverBusiness']);
            QUERY_CACHE.invalidateQueries(['naverItemsWithOptions']);
        } }));
};
export var useCreateNaverSchedule = function (config) {
    return useMutation(function (payload) {
        var businessId = payload.businessId, bizItemId = payload.bizItemId, storeId = payload.storeId, rest = __rest(payload, ["businessId", "bizItemId", "storeId"]);
        return LambdaClient.getInstance()
            .post("/naver/business/".concat(businessId, "/bizItems/").concat(bizItemId, "/schedules?storeId=").concat(storeId), rest)
            .then(function (result) { return result.data; });
    }, __assign(__assign({}, config), { onSuccess: function () {
            QUERY_CACHE.invalidateQueries(['naverSchedules']);
        } }));
};
export var useUpdatetNaverSchedule = function (config) {
    return useMutation(function (payload) {
        var businessId = payload.businessId, bizItemId = payload.bizItemId, storeId = payload.storeId, rest = __rest(payload, ["businessId", "bizItemId", "storeId"]);
        return LambdaClient.getInstance()
            .put("/naver/business/".concat(businessId, "/bizItems/").concat(bizItemId, "/schedules/").concat(rest.scheduleId, "?storeId=").concat(storeId), rest)
            .then(function (result) { return result.data; });
    }, __assign(__assign({}, config), { onSuccess: function () {
            QUERY_CACHE.invalidateQueries(['naverSchedules']);
        } }));
};
export var useDeleteNaverSchedule = function (config) {
    return useMutation(function (payload) {
        var businessId = payload.businessId, bizItemId = payload.bizItemId, scheduleId = payload.scheduleId, storeId = payload.storeId;
        return LambdaClient.getInstance()
            .remove("/naver/business/".concat(businessId, "/bizItems/").concat(bizItemId, "/schedules/").concat(scheduleId, "?storeId=").concat(storeId))
            .then(function (result) { return result.data; });
    }, __assign(__assign({}, config), { onSuccess: function () {
            QUERY_CACHE.invalidateQueries(['naverSchedules']);
        } }));
};
export var useCreateAndDeleteNaverHoliday = function (config) {
    return useMutation(function (payload) {
        var businessId = payload.businessId, bizItemId = payload.bizItemId, storeId = payload.storeId, rest = __rest(payload, ["businessId", "bizItemId", "storeId"]);
        return LambdaClient.getInstance()
            .post("/naver/business/".concat(businessId, "/bizItems/").concat(bizItemId, "/holiday-schedules?storeId=").concat(storeId), rest)
            .then(function (result) { return result.data; });
    }, __assign(__assign({}, config), { onSuccess: function () {
            QUERY_CACHE.invalidateQueries(['naverHolidaySchedules']);
        } }));
};
export var useRefundPolies = function (params, config) {
    return useQuery(['naverRefundPolies', params], function (params) {
        var queryKey = params.queryKey;
        return LambdaClient.getInstance()
            .get("/naver/business/".concat(queryKey[1].businessId, "/refund-polies"))
            .then(function (result) { return result.data; });
    }, __assign(__assign({}, config), { staleTime: 60000 }));
};
export var useCreateRefundPolies = function (config) {
    return useMutation(function (payload) {
        var businessId = payload.businessId, rest = __rest(payload, ["businessId"]);
        return LambdaClient.getInstance()
            .post("/naver/business/".concat(businessId, "/refund-polies"), rest)
            .then(function (result) { return result.data; });
    }, __assign(__assign({}, config), { onSuccess: function () {
            QUERY_CACHE.invalidateQueries(['naverSchedules']);
        } }));
};
export var useGetNaverHairTags = function (config) {
    return useQuery(['getNaverHairTags'], function (params) {
        return LambdaClient.getInstance()
            .get("/naver/bookng-hair-tags")
            .then(function (result) { return result.data; });
    }, __assign({}, config));
};
export var useCreateHairTag = function (config) {
    return useMutation(function (payload) {
        var bookingId = payload.bookingId, hairtagCategories = payload.hairtagCategories;
        return LambdaClient.getInstance()
            .post("/naver/bookings/".concat(bookingId, "/hair-tag-mappings"), hairtagCategories)
            .then(function (result) { return result.data; });
    }, __assign(__assign({}, config), { onSuccess: function () {
            QUERY_CACHE.invalidateQueries(['getNaverHairTagsById']);
        } }));
};
export var useUpdateTag = function (config) {
    return useMutation(function (payload) {
        var bookingId = payload.bookingId, hairtagCategories = payload.hairtagCategories;
        return LambdaClient.getInstance()
            .post("/naver/bookings/".concat(bookingId, "/hair-tag-mappings"), hairtagCategories)
            .then(function (result) { return result.data; });
    }, __assign(__assign({}, config), { onSuccess: function () {
            QUERY_CACHE.invalidateQueries(['getNaverHairTagsById']);
        } }));
};
export var useGetNaverHairTagsById = function (params, config) {
    return useQuery(['getNaverHairTagsById', params], function (params) {
        var queryKey = params.queryKey;
        return LambdaClient.getInstance()
            .get("/naver/bookings/".concat(queryKey[1].bookingId, "/hair-tag-mappings"))
            .then(function (result) { return result.data; });
    }, __assign(__assign({}, config), { staleTime: 60000 }));
};
export var useNaverHolidaySchedules = function (params, config) {
    return useQuery(['naverHolidaySchedules', params], function (params) {
        var queryKey = params.queryKey;
        return LambdaClient.getInstance()
            .get("/naver/business/".concat(queryKey[1].businessId, "/bizItems/").concat(queryKey[1].bizItemId, "/holiday-schedules"))
            .then(function (result) { return result.data; });
    }, __assign(__assign({}, config), { staleTime: 60000 }));
};
export var useDeleteNaverStoreCache = function (config) {
    return useMutation(function (payload) {
        return LambdaClient.getInstance()
            .remove("/naver/store-cache/".concat(payload.storeId))
            .then(function (result) { return result.data; });
    }, __assign({}, config));
};
export var PUBLIC_HOLIDAY_FROM_NAVER = [
    {
        holidayType: 'PUBLIC',
        holidayName: '신정',
        repetitionType: 'YEARLY',
        repetitionStartMonth: 1,
        repetitionStartDay: 1,
        repetitionEndMonth: 1,
        repetitionEndDay: 1,
        startDate: '2023-04-12',
        endDate: '9999-12-31',
        calendarType: 'SOLAR'
    },
    {
        holidayType: 'PUBLIC',
        holidayName: '설날 당일',
        repetitionType: 'YEARLY',
        repetitionStartMonth: 1,
        repetitionStartDay: 1,
        repetitionEndMonth: 1,
        repetitionEndDay: 1,
        startDate: '2023-04-12',
        endDate: '9999-12-31',
        calendarType: 'LUNAR',
        extraHolidays: [-1, 0, 1]
    },
    {
        holidayType: 'PUBLIC',
        holidayName: '삼일절',
        repetitionType: 'YEARLY',
        repetitionStartMonth: 3,
        repetitionStartDay: 1,
        repetitionEndMonth: 3,
        repetitionEndDay: 1,
        startDate: '2023-04-12',
        endDate: '9999-12-31',
        calendarType: 'SOLAR'
    },
    {
        holidayType: 'PUBLIC',
        holidayName: '석가탄신일',
        repetitionType: 'YEARLY',
        repetitionStartMonth: 4,
        repetitionStartDay: 8,
        repetitionEndMonth: 4,
        repetitionEndDay: 8,
        startDate: '2023-04-12',
        endDate: '9999-12-31',
        calendarType: 'LUNAR'
    },
    {
        holidayType: 'PUBLIC',
        holidayName: '어린이날',
        repetitionType: 'YEARLY',
        repetitionStartMonth: 5,
        repetitionStartDay: 5,
        repetitionEndMonth: 5,
        repetitionEndDay: 5,
        startDate: '2023-04-12',
        endDate: '9999-12-31',
        calendarType: 'SOLAR'
    },
    {
        holidayType: 'PUBLIC',
        holidayName: '현충일',
        repetitionType: 'YEARLY',
        repetitionStartMonth: 6,
        repetitionStartDay: 6,
        repetitionEndMonth: 6,
        repetitionEndDay: 6,
        startDate: '2023-04-12',
        endDate: '9999-12-31',
        calendarType: 'SOLAR'
    },
    {
        holidayType: 'PUBLIC',
        holidayName: '광복절',
        repetitionType: 'YEARLY',
        repetitionStartMonth: 8,
        repetitionStartDay: 15,
        repetitionEndMonth: 8,
        repetitionEndDay: 15,
        startDate: '2023-04-12',
        endDate: '9999-12-31',
        calendarType: 'SOLAR'
    },
    {
        holidayType: 'PUBLIC',
        holidayName: '개천절',
        repetitionType: 'YEARLY',
        repetitionStartMonth: 10,
        repetitionStartDay: 3,
        repetitionEndMonth: 10,
        repetitionEndDay: 3,
        startDate: '2023-04-12',
        endDate: '9999-12-31',
        calendarType: 'SOLAR'
    },
    {
        holidayType: 'PUBLIC',
        holidayName: '한글날',
        repetitionType: 'YEARLY',
        repetitionStartMonth: 10,
        repetitionStartDay: 9,
        repetitionEndMonth: 10,
        repetitionEndDay: 9,
        startDate: '2023-04-12',
        endDate: '9999-12-31',
        calendarType: 'SOLAR'
    },
    {
        holidayType: 'PUBLIC',
        holidayName: '추석 전날',
        repetitionType: 'YEARLY',
        repetitionStartMonth: 8,
        repetitionStartDay: 15,
        repetitionEndMonth: 8,
        repetitionEndDay: 15,
        startDate: '2023-04-12',
        endDate: '9999-12-31',
        calendarType: 'LUNAR',
        extraHolidays: [-1, 0, 1]
    },
    {
        holidayType: 'PUBLIC',
        holidayName: '크리스마스',
        repetitionType: 'YEARLY',
        repetitionStartMonth: 12,
        repetitionStartDay: 25,
        repetitionEndMonth: 12,
        repetitionEndDay: 25,
        startDate: '2023-04-12',
        endDate: '9999-12-31',
        calendarType: 'SOLAR'
    }
];
