var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseModel } from './BaseModel';
var INVOICE_STATUS;
(function (INVOICE_STATUS) {
    /** 미결제, 사용자의 결제 혹은 관리자의 승인을 기다리는 상태 (오픈) */
    INVOICE_STATUS["OPENED"] = "opened";
    /** 미결제, 조정 상태 (조정) */
    INVOICE_STATUS["IN_ADJUST"] = "in_adjust";
    /** 미결제, 자동 결제가 예약된 상태 - 매일 결제 진행됨 (확정) */
    INVOICE_STATUS["SCHEDULED"] = "scheduled";
    /** 결제 진행 중 */
    INVOICE_STATUS["IN_PROGRESS"] = "in_progress";
    /** 결제 실패 중 */
    INVOICE_STATUS["IN_HOLD"] = "in_hold";
    /** 결제가 완료된 상태 */
    INVOICE_STATUS["PAID"] = "paid";
    /** 결제는 안되었지만 완료 완료 */
    INVOICE_STATUS["COMPLETED"] = "completed";
    /** 취소된 상태 */
    INVOICE_STATUS["CANCELLED"] = "cancelled";
    /** in_hold 상태에서 5번 이상 결제 실패 */
    INVOICE_STATUS["FAILED"] = "failed";
})(INVOICE_STATUS || (INVOICE_STATUS = {}));
var OurInvoiceModel = /** @class */ (function (_super) {
    __extends(OurInvoiceModel, _super);
    function OurInvoiceModel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(OurInvoiceModel.prototype, "price", {
        get: function () {
            return (this.totalPrice || 0) + (this.totalVat || 0) - (this.totalDiscount || 0);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(OurInvoiceModel.prototype, "title", {
        get: function () {
            var _a;
            return ((_a = this.subscriptions) === null || _a === void 0 ? void 0 : _a.map(function (item) { return item.name; }).join(', ')) || this.description;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(OurInvoiceModel.prototype, "refundableAmount", {
        get: function () {
            var _a;
            if (this.status === INVOICE_STATUS.OPENED ||
                this.status === INVOICE_STATUS.PAID ||
                this.status === INVOICE_STATUS.CANCELLED) {
                return this.price - (((_a = this.iamportData) === null || _a === void 0 ? void 0 : _a.cancel_amount) || 0);
            }
            else {
                return 0;
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(OurInvoiceModel.prototype, "typeToString", {
        get: function () {
            switch (this.status) {
                case INVOICE_STATUS.OPENED:
                    return '결제완료';
                case INVOICE_STATUS.PAID:
                    return '결제완료';
                case INVOICE_STATUS.CANCELLED:
                    return this.refundableAmount ? '부분취소' : '결제취소';
                default:
                    return this.status;
            }
        },
        enumerable: false,
        configurable: true
    });
    OurInvoiceModel.INVOICE_STATUS = INVOICE_STATUS;
    return OurInvoiceModel;
}(BaseModel));
export { OurInvoiceModel };
