var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useMutation } from 'react-query';
import { QUERY_CACHE } from 'renderer/queries';
import { usePostMutation, getItems } from './BaseQuery';
import { StorePictureModel } from 'renderer/models';
import { RestClient } from 'renderer/utils';
var getMutationConfig = function (storeId, query) {
    return {
        onSuccess: function () {
            // TODO: 필터에 맞춰서 optimise
            QUERY_CACHE.invalidateQueries(['customerPicture', storeId]);
        }
    };
};
export var useCustomerPictures = function (storeId, query, config) {
    var queryKey = ['customerPicture', storeId, query];
    return getItems(queryKey, "/stores/".concat(storeId, "/pictures"), { params: query }, __assign(__assign({}, config), { staleTime: 60000 }));
};
export var useCreateStorePictures = function (storeId, query, config) {
    return usePostMutation(__assign(__assign({ url: "/stores/".concat(storeId, "/pictures").concat((query === null || query === void 0 ? void 0 : query.salesHistoryId) ? "?salesHistoryId=".concat(query.salesHistoryId) : '') }, getMutationConfig(storeId, query)), config));
};
export var useDeleteStorePictures = function (storeId, config) {
    return useMutation(function (payload) {
        return RestClient.getInstance()
            .remove("/stores/".concat(storeId, "/pictures/").concat(payload.id))
            .then(function (result) { return new StorePictureModel(result.data); });
    }, __assign(__assign({}, config), { onSuccess: function () {
            QUERY_CACHE.invalidateQueries(['customerPicture', storeId]);
        } }));
};
