import { QueryClient } from 'react-query';
import * as CustomerQuery from './customer';
import * as CustomerGroupQuery from './customerGroup';
import * as ManagerQuery from './manager';
import * as TicketTemplateQuery from './ticketTemplate';
import * as StoreQuery from './store';
import * as ProductQuery from './product';
import * as ScheduleQuery from './schedule';
import * as CategoryQuery from './category';
import * as StorePaymentMethodQuery from './storePayment';
import * as MessageTemplateQuery from './message';
import * as PaymentHistoryQuery from './paymentHistory';
import * as AdminQuery from './admin';
import * as TicketQuery from './tickets';
import * as FranchisesQuery from './franchises';
import * as PageQuery from './page';
import * as CustomerRelationQuery from './customerRelation';
import * as StorePictureQuery from './storePictureQuery';
import * as StoreAccountQuery from './storeAccount';
import * as NaverLambdaQuery from './naverLambda';
import * as StoreSupplierQuery from './storeSupplier';
import * as StoreStockPurchaseQuery from './storeStockPurchase';
import * as StoreStockQuery from './storeStock';
import * as StoreLedgerQuery from './storeLedger';
import * as StoreCourseQuery from './storeCourse';
// TODO: remove
var QUERY_CACHE = new QueryClient({
    defaultOptions: {
        queries: {
            retryDelay: function (attemptIndex) { return Math.min(1000 * Math.pow(2, attemptIndex), 30000); },
            refetchOnWindowFocus: false
        }
    }
});
export { QUERY_CACHE, CustomerQuery, CustomerGroupQuery, ManagerQuery, TicketTemplateQuery, StoreQuery, ProductQuery, ScheduleQuery, CategoryQuery, StorePaymentMethodQuery, MessageTemplateQuery, PaymentHistoryQuery, AdminQuery, TicketQuery, FranchisesQuery, PageQuery, CustomerRelationQuery, StorePictureQuery, StoreAccountQuery, NaverLambdaQuery, StoreSupplierQuery, StoreStockPurchaseQuery, StoreStockQuery, StoreLedgerQuery, StoreCourseQuery };
