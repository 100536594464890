var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { LOCAL_STORAGE_DEVICE_UNIQUE, LOCAL_STORAGE_BACKUP_SCHEDULES } from 'renderer/constants';
import { createNanoId } from './nanoid';
import { ScheduleModel } from 'renderer/models';
import { isEmpty } from 'lodash';
function stringHasJsonStructure(str) {
    if (typeof str !== 'string')
        return false;
    try {
        var result = JSON.parse(str);
        var type = Object.prototype.toString.call(result);
        return type === '[object Object]' || type === '[object Array]';
    }
    catch (err) {
        return false;
    }
}
function convertToString(value) {
    return typeof value === 'object' ? JSON.stringify(value) : value;
}
function convertStoredValue(value) {
    var isNullOrUndefined = value === 'null' || value === 'undefined';
    return isNullOrUndefined
        ? value === 'null'
            ? null
            : undefined
        : stringHasJsonStructure(value)
            ? JSON.stringify(value)
            : value;
}
export var LocalStorage = {
    set: function (key, value) {
        if (value) {
            localStorage.setItem(key, convertToString(value));
        }
    },
    get: function (key) {
        return convertStoredValue(localStorage.getItem(key));
    },
    getDeviceUnique: function () {
        var deviceUnique = localStorage.getItem(LOCAL_STORAGE_DEVICE_UNIQUE);
        var deviceUnique1 = localStorage.getItem(undefined);
        if (!deviceUnique) {
            if (deviceUnique1) {
                deviceUnique = deviceUnique1;
            }
            else {
                deviceUnique = "".concat(createNanoId(), "_").concat(Date.now());
            }
            localStorage.setItem(LOCAL_STORAGE_DEVICE_UNIQUE, deviceUnique);
        }
        return deviceUnique;
    },
    removeItem: function (key) {
        localStorage.removeItem(key);
    }
};
export var SessionStorage = {
    set: function (key, value) {
        if (value) {
            sessionStorage.setItem(key, convertToString(value));
        }
    },
    get: function (key) {
        return convertStoredValue(sessionStorage.getItem(key));
    },
    removeItem: function (key) {
        sessionStorage.removeItem(key);
    }
};
export function setWithExpiry(key, value, ttl) {
    var item = {
        value: value,
        expiry: new Date().getTime() + ttl
    };
    localStorage.setItem(key, JSON.stringify(item));
}
export function getWithExpiry(key) {
    var itemString = window.localStorage.getItem(key);
    if (!itemString)
        return null;
    var item = JSON.parse(itemString);
    var isExpired = new Date().getTime() > item.expiry;
    if (isExpired) {
        localStorage.removeItem(key);
        return null;
    }
    return item.value;
}
export var getScheduleFromLocal = function () {
    // FIXME: 이거 왜 이래?
    var items = JSON.parse(localStorage.getItem(LOCAL_STORAGE_BACKUP_SCHEDULES));
    return items === null || items === void 0 ? void 0 : items.map(function (item) {
        return new ScheduleModel(item);
    });
};
export var setScheduleToLocal = function (items) {
    var _a, _b, _c;
    // 오늘 날짜를 기준으로 이전 5일과 이후 5일의 범위를 계산
    if (isEmpty(items)) {
        return;
    }
    var today = new Date();
    var startRange = new Date(today);
    var endRange = new Date(today);
    var storeId = (_a = items[0]) === null || _a === void 0 ? void 0 : _a.storeId;
    startRange.setDate(today.getDate() - 5);
    endRange.setDate(today.getDate() + 5);
    // 필터링 된 스케줄 데이터
    var filteredSchedules = items.filter(function (item) {
        var scheduleDate = new Date(item.dateOption.startedAt);
        return scheduleDate >= startRange && scheduleDate <= endRange && item.storeId === storeId;
    });
    var filtered = function (item) {
        var scheduleDate = new Date(item.dateOption.startedAt);
        return scheduleDate >= startRange && scheduleDate <= endRange && item.storeId === storeId;
    };
    // 기존 로컬스토리지의 스케줄 데이터 불러오기
    var existingSchedules = JSON.parse(localStorage.getItem(LOCAL_STORAGE_BACKUP_SCHEDULES) || '[]');
    // 새로운 데이터와 기존 데이터를 병합하고 중복 제거 (ID로 중복 제거)
    var scheduleMap = new Map();
    (_c = (_b = __spreadArray(__spreadArray([], existingSchedules, true), items, true)) === null || _b === void 0 ? void 0 : _b.filter(filtered)) === null || _c === void 0 ? void 0 : _c.forEach(function (schedule) {
        scheduleMap.set(schedule.id, schedule);
    });
    var mergedSchedules = Array.from(scheduleMap.values());
    // FIXME: IndexDB 또는 기본 용량 줄이기
    // 병합된 데이터를 로컬스토리지에 저장
    try {
        localStorage.setItem(LOCAL_STORAGE_BACKUP_SCHEDULES, JSON.stringify(mergedSchedules));
    }
    catch (error) { }
};
