export var truncateString = function (str, num) {
    if (str.length <= num) {
        return str;
    }
    return str.slice(0, num) + '...';
};
export var toCamelCase = function (words) {
    return words
        .map(function (word, index) {
        if (index === 0) {
            // 첫 번째 단어는 소문자로 유지
            return word.toLowerCase();
        }
        else {
            // 첫 번째 단어 이후의 단어는 첫 글자만 대문자로 변환
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        }
    })
        .join('');
};
var TEST_STRING = 'ㅇㅇ￦♥♡★☆▶▷◀◁∩●■○□▲▼▒♨※™℡♬♪☞☜♂♀㈜⊙◆◇♣♧☎∑▣㉿『』◐◑†안녕 \n 예약시간보다 너무 일찍 방문하실 경우 문 밖에서 대기하셔야 하는 상황이 생길 수 있으니 너무 급하지 않게 도착해주세요^^​\n\n3. 호흡기 증상이 있으신 ￦♥♡★☆▶▷◀◁∩●■○□▲▼▒♨※™℡♬♪☞☜♂♀㈜⊙◆◇♣♧☎∑▣㉿『』◐◑ dd 😀';
export var checkEucKrCompatibility = function (input) {
    var eucKrCompatibleRanges = [
        [0x0000, 0x007f],
        [0xac00, 0xd7a3],
        [0x3131, 0x318e],
        [0x3200, 0x321e],
        [0xff01, 0xff5e],
        [0x2010, 0x2027],
        [0x2030, 0x2046] // 추가 특수 기호
    ];
    // EUC-KR에서 지원하는 추가 특수 기호들
    var additionalSpecialChars = new Set('·￦♥♡★☆▶▷◀◁∩●■○□▲▼▒♨※™℡♬♪☞☜♂♀㈜⊙◆◇♣♧☎∑▣㉿『』◐◑†');
    var incompatibleChars = [];
    for (var _i = 0, input_1 = input; _i < input_1.length; _i++) {
        var char = input_1[_i];
        var code = char.charCodeAt(0);
        var isCompatible = false;
        for (var _a = 0, eucKrCompatibleRanges_1 = eucKrCompatibleRanges; _a < eucKrCompatibleRanges_1.length; _a++) {
            var _b = eucKrCompatibleRanges_1[_a], start = _b[0], end = _b[1];
            if (code >= start && code <= end) {
                isCompatible = true;
                break;
            }
        }
        if (!isCompatible && !additionalSpecialChars.has(char)) {
            console.log(char);
            incompatibleChars.push(char);
        }
    }
    return {
        compatible: incompatibleChars.length === 0,
        incompatibleChars: incompatibleChars
    };
};
/**
 * Zero Width Space 문자와 HTML 엔티티를 제거하는 함수
 * @param input 입력 문자열
 * @returns Zero Width Space가 제거된 문자열
 */
export function removeZeroWidthSpace(input) {
    // Zero Width Space의 유니코드
    return input.replace(/\u200B/g, '');
}
