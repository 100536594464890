export var STRING_KEY;
(function (STRING_KEY) {
    STRING_KEY["TEST"] = "TEST";
    STRING_KEY["APP_NAME"] = "APP_NAME";
    STRING_KEY["SUPER_ADMIN_SIDE_MENU_STORE"] = "SUPER_ADMIN_SIDE_MENU_STORE";
    STRING_KEY["SUPER_ADMIN_SIDE_MENU_DASHBOARD"] = "SUPER_ADMIN_SIDE_MENU_DASHBOARD";
    STRING_KEY["SUPER_ADMIN_SIDE_MENU_ALIMTALK_TEMPLATES"] = "SUPER_ADMIN_SIDE_MENU_ALIMTALK_TEMPLATES";
    STRING_KEY["SUPER_ADMIN_SIDE_MENU_INVOICES"] = "SUPER_ADMIN_SIDE_MENU_INVOICES";
    STRING_KEY["SUPER_ADMIN_SIDE_MENU_FRANCHISES"] = "SUPER_ADMIN_SIDE_MENU_FRANCHISES";
    STRING_KEY["SUPER_ADMIN_SIDE_MENU_PAYMENTS"] = "SUPER_ADMIN_SIDE_MENU_PAYMENTS";
    STRING_KEY["SUPER_ADMIN_SIDE_MENU_MIGRATION"] = "SUPER_ADMIN_SIDE_MENU_MIGRATION";
    STRING_KEY["FRANCHISE_ADMIN_SIDE_MENU_LIST"] = "FRANCHISE_ADMIN_SIDE_MENU_LIST";
    STRING_KEY["FRANCHISE_ADMIN_SIDE_MENU_INVOICES"] = "FRANCHISE_ADMIN_SIDE_MENU_INVOICES";
    STRING_KEY["ADMIN_SIDE_MENU_STORE_MANAGEMENT"] = "ADMIN_SIDE_MENU_STORE_MANAGEMENT";
    STRING_KEY["ADMIN_SIDE_MENU_EMPLOYEE_MANAGEMENT"] = "ADMIN_SIDE_MENU_EMPLOYEE_MANAGEMENT";
    STRING_KEY["ADMIN_SIDE_MENU_STORE_MANAGEMENT_SUB_INFO"] = "ADMIN_SIDE_MENU_STORE_MANAGEMENT_SUB_INFO";
    STRING_KEY["ADMIN_SIDE_MENU_STORE_MANAGEMENT_SUB_POINT"] = "ADMIN_SIDE_MENU_STORE_MANAGEMENT_SUB_POINT";
    STRING_KEY["ADMIN_SIDE_MENU_STORE_MANAGEMENT_SUB_SUBSCRIPTION"] = "ADMIN_SIDE_MENU_STORE_MANAGEMENT_SUB_SUBSCRIPTION";
    STRING_KEY["ADMIN_SIDE_MENU_STORE_MANAGEMENT_SUB_APP_INVOICE"] = "ADMIN_SIDE_MENU_STORE_MANAGEMENT_SUB_APP_INVOICE";
    STRING_KEY["ADMIN_SIDE_MENU_STORE_MANAGEMENT_SUB_PYAMENT_METHODS"] = "ADMIN_SIDE_MENU_STORE_MANAGEMENT_SUB_PYAMENT_METHODS";
    STRING_KEY["ADMIN_SIDE_MENU_EMPLOYEE_MANAGEMENT_SUB_EMPLOYEE"] = "ADMIN_SIDE_MENU_EMPLOYEE_MANAGEMENT_SUB_EMPLOYEE";
    STRING_KEY["ADMIN_SIDE_MENU_EMPLOYEE_MANAGEMENT_SUB_INCENTIVE"] = "ADMIN_SIDE_MENU_EMPLOYEE_MANAGEMENT_SUB_INCENTIVE";
    STRING_KEY["ADMIN_SIDE_MENU_GO_HOME"] = "ADMIN_SIDE_MENU_GO_HOME";
    STRING_KEY["SIDE_MENU_GROUP_RESERVATION"] = "SIDE_MENU_GROUP_RESERVATION";
    STRING_KEY["SIDE_MENU_GROUP_CUSTOMER"] = "SIDE_MENU_GROUP_CUSTOMER";
    STRING_KEY["SIDE_MENU_GROUP_SALES"] = "SIDE_MENU_GROUP_SALES";
    STRING_KEY["SIDE_MENU_GROUP_SALES_STAT"] = "SIDE_MENU_GROUP_SALES_STAT";
    STRING_KEY["SIDE_MENU_GROUP_USES"] = "SIDE_MENU_GROUP_USES";
    STRING_KEY["SIDE_MENU_GROUP_SMS"] = "SIDE_MENU_GROUP_SMS";
    STRING_KEY["SIDE_MENU_GROUP_MARKETING"] = "SIDE_MENU_GROUP_MARKETING";
    STRING_KEY["SIDE_MENU_SUB_RESERVATION"] = "SIDE_MENU_SUB_RESERVATION";
    STRING_KEY["SIDE_MENU_SUB_TRANSFERS"] = "SIDE_MENU_SUB_TRANSFERS";
    STRING_KEY["SIDE_MENU_SUB_DEPOSIT"] = "SIDE_MENU_SUB_DEPOSIT";
    STRING_KEY["SIDE_MENU_SUB_CUSTOMER"] = "SIDE_MENU_SUB_CUSTOMER";
    STRING_KEY["SIDE_MENU_SUB_CUSTOMER_PREPAID"] = "SIDE_MENU_SUB_CUSTOMER_PREPAID";
    STRING_KEY["SIDE_MENU_SUB_TICKET_TEMPLATE"] = "SIDE_MENU_SUB_TICKET_TEMPLATE";
    STRING_KEY["SIDE_MENU_SUB_SALES"] = "SIDE_MENU_SUB_SALES";
    STRING_KEY["SIDE_MENU_SUB_STOCKS"] = "SIDE_MENU_SUB_STOCKS";
    STRING_KEY["SIDE_MENU_SUB_EXPENSES"] = "SIDE_MENU_SUB_EXPENSES";
    STRING_KEY["SIDE_MENU_SUB_SALES_EMPLOYEE"] = "SIDE_MENU_SUB_SALES_EMPLOYEE";
    STRING_KEY["SIDE_MENU_SUB_SALES_STAT"] = "SIDE_MENU_SUB_SALES_STAT";
    STRING_KEY["SIDE_MENU_SUB_USING_STAT"] = "SIDE_MENU_SUB_USING_STAT";
    STRING_KEY["SIDE_MENU_SUB_USES"] = "SIDE_MENU_SUB_USES";
    STRING_KEY["SIDE_MENU_SUB_PREPAID"] = "SIDE_MENU_SUB_PREPAID";
    STRING_KEY["SIDE_MENU_SUB_TICKET"] = "SIDE_MENU_SUB_TICKET";
    STRING_KEY["SIDE_MENU_SUB_SMS"] = "SIDE_MENU_SUB_SMS";
    STRING_KEY["SIDE_MENU_SUB_SMS_HISTORY"] = "SIDE_MENU_SUB_SMS_HISTORY";
    STRING_KEY["SIDE_MENU_SUB_SMS_MESSAGE_TEMPLATES"] = "SIDE_MENU_SUB_SMS_MESSAGE_TEMPLATES";
    STRING_KEY["SIDE_MENU_SUB_MULTI_LINK"] = "SIDE_MENU_SUB_MULTI_LINK";
    STRING_KEY["SIDE_MENU_SUB_MARKETING"] = "SIDE_MENU_SUB_MARKETING";
    STRING_KEY["SIDE_MENU_SUB_SMS_SETTINGS"] = "SIDE_MENU_SUB_SMS_SETTINGS";
    STRING_KEY["SIDE_MENU_SUB_SMS_AUTO_SETTINGS"] = "SIDE_MENU_SUB_SMS_AUTO_SETTINGS";
    STRING_KEY["SIDE_MENU_SUB_APP_MANAGEMENT"] = "SIDE_MENU_SUB_APP_MANAGEMENT";
    STRING_KEY["SIDE_MENU_SUB_COUPON"] = "SIDE_MENU_SUB_COUPON";
    STRING_KEY["CALENDAR_DISPLAY_MODE_DAY"] = "CALENDAR_DISPLAY_MODE_DAY";
    STRING_KEY["CALENDAR_DISPLAY_MODE_WEEK"] = "CALENDAR_DISPLAY_MODE_WEEK";
    STRING_KEY["CALENDAR_DISPLAY_MODE_MONTH"] = "CALENDAR_DISPLAY_MODE_MONTH";
    STRING_KEY["PAGE_TITLE_RESERVATION"] = "PAGE_TITLE_RESERVATION";
    STRING_KEY["PAGE_TITLE_PRODUCT_LIST"] = "PAGE_TITLE_PRODUCT_LIST";
    STRING_KEY["PAGE_TITLE_TICKET_TEMPLATE_LIST"] = "PAGE_TITLE_TICKET_TEMPLATE_LIST";
})(STRING_KEY || (STRING_KEY = {}));
