var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseModel, StoreCourseProgressModel } from 'renderer/models';
var STORE_COUSE_LEVELS;
(function (STORE_COUSE_LEVELS) {
    STORE_COUSE_LEVELS["BEGINNER"] = "BEGINNER";
    STORE_COUSE_LEVELS["INTERMEDIATE"] = "INTERMEDIATE";
    STORE_COUSE_LEVELS["ADVANCED"] = "ADVANCED";
})(STORE_COUSE_LEVELS || (STORE_COUSE_LEVELS = {}));
var STORE_COUSE_REWARD_TYPES;
(function (STORE_COUSE_REWARD_TYPES) {
    STORE_COUSE_REWARD_TYPES["POINT"] = "POINT";
    STORE_COUSE_REWARD_TYPES["DURATION"] = "DURATION";
})(STORE_COUSE_REWARD_TYPES || (STORE_COUSE_REWARD_TYPES = {}));
var StoreCourseModel = /** @class */ (function (_super) {
    __extends(StoreCourseModel, _super);
    function StoreCourseModel(data) {
        var _this = this;
        var _a;
        _this = _super.call(this, data) || this;
        _this.storeProgresses = ((_a = data.storeProgresses) === null || _a === void 0 ? void 0 : _a.map(function (item) { return new StoreCourseProgressModel(item); })) || [];
        return _this;
    }
    Object.defineProperty(StoreCourseModel.prototype, "progress", {
        get: function () {
            return this.storeProgresses && this.storeProgresses[0];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StoreCourseModel.prototype, "isCompleted", {
        get: function () {
            return this.progress && !!this.progress.rewardedAt;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StoreCourseModel.prototype, "availableReward", {
        get: function () {
            var _a;
            return this.rewardValue && this.rewardCondition && !((_a = this.progress) === null || _a === void 0 ? void 0 : _a.isRewardUnavailable);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StoreCourseModel.prototype, "progressStutas", {
        get: function () {
            var progress = this.progress;
            if (!progress) {
                return 'notStarted';
            }
            if (this.isCompleted) {
                return 'completed';
            }
            return 'inProgress';
        },
        enumerable: false,
        configurable: true
    });
    StoreCourseModel.STORE_COUSE_LEVELS = STORE_COUSE_LEVELS;
    StoreCourseModel.STORE_COUSE_REWARD_TYPES = STORE_COUSE_REWARD_TYPES;
    return StoreCourseModel;
}(BaseModel));
export { StoreCourseModel };
