import * as Bowser from 'bowser';
var getParser = function () {
    return Bowser.getParser(window.navigator.userAgent);
};
export var isIE = function () {
    var browserName = getParser().getBrowser().name;
    return browserName === 'Internet Explorer';
};
export function isMobile() {
    return getPlatformType() !== 'desktop' || !['linux', 'macos', 'windows'].includes(getParser().getOSName(true));
}
export function getPlatformType() {
    return getParser().getPlatformType(true);
}
export function isIOSWKWebView() {
    var _a;
    var isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    var isWKWebView = !!window.webkit && !!((_a = window.webkit) === null || _a === void 0 ? void 0 : _a.messageHandlers);
    return isIOS && isWKWebView;
}
