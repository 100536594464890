var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as dayjs from 'dayjs';
import { Application } from './App';
import { EntireLoader } from 'renderer/components/EntireLoader';
import { setValidator } from 'renderer/utils';
import isBetween from 'dayjs/plugin/isBetween';
import relativeTime from 'dayjs/plugin/relativeTime';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import notification from 'antd/lib/notification';
import { IS_ELECTRON } from './constants';
import { BrowserRouter, MemoryRouter } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import { QUERY_CACHE } from './queries';
import 'antd/dist/reset.css';
import ConfigProvider from 'antd/lib/config-provider';
import koKR from 'antd/lib/locale/ko_KR';
import 'dayjs/locale/ko';
import { VALIDATE_MESSAGES } from './locale';
import { COLORS } from 'renderer/styles';
import { AntModalGlobalStyled } from 'renderer/components';
dayjs.extend(isBetween);
dayjs.extend(relativeTime);
setValidator();
dayjs.locale('ko', {
    name: 'ko',
    weekdays: '일요일_월요일_화요일_수요일_목요일_금요일_토요일'.split('_'),
    weekdaysShort: '일_월_화_수_목_금_토'.split('_'),
    weekdaysMin: '일_월_화_수_목_금_토'.split('_'),
    months: '1월_2월_3월_4월_5월_6월_7월_8월_9월_10월_11월_12월'.split('_'),
    monthsShort: '1월_2월_3월_4월_5월_6월_7월_8월_9월_10월_11월_12월'.split('_'),
    // FIXME :  이거 때문에 추가 안트 로케일 cellDateFormat이 rangePicker에서 안먹힘
    ordinal: function (n) { return "".concat(n); },
    formats: {
        LT: 'A h:mm',
        LTS: 'A h:mm:ss',
        L: 'YYYY.MM.DD.',
        LL: 'YYYY년 MMMM D일',
        LLL: 'YYYY년 MMMM D일 A h:mm',
        LLLL: 'YYYY년 MMMM D일 dddd A h:mm',
        l: 'YYYY.MM.DD.',
        ll: 'YYYY년 MMMM D일',
        lll: 'YYYY년 MMMM D일 A h:mm',
        llll: 'YYYY년 MMMM D일 dddd A h:mm'
    },
    meridiem: function (hour) { return (hour < 12 ? '오전' : '오후'); },
    relativeTime: {
        future: '%s 후',
        past: '%s 전',
        s: '몇 초',
        m: '1분',
        mm: '%d분',
        h: '한 시간',
        hh: '%d시간',
        d: '하루',
        dd: '%d일',
        M: '한 달',
        MM: '%d달',
        y: '일 년',
        yy: '%d년'
    }
});
var initialSearch = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.search;
notification.config({
    placement: 'topLeft',
    duration: 2
});
var RootRouter = IS_ELECTRON ? MemoryRouter : BrowserRouter;
ReactDOM.render(React.createElement(DndProvider, { backend: HTML5Backend },
    React.createElement(React.Suspense, { fallback: React.createElement(EntireLoader, { open: true }) },
        React.createElement(RootRouter, null,
            React.createElement(QueryClientProvider, { client: QUERY_CACHE },
                React.createElement(ConfigProvider, { locale: __assign(__assign({}, koKR), { DatePicker: __assign(__assign({}, koKR.DatePicker), { lang: __assign(__assign({}, koKR.DatePicker.lang), { cellDateFormat: 'D' }) }) }), form: { validateMessages: VALIDATE_MESSAGES }, theme: {
                        token: {
                            // fontFamily: 'Noto Sans KR, sans-serif',
                            colorPrimary: COLORS.primary1,
                            colorInfo: '#3ba0ff',
                            colorSuccess: '#52c41a',
                            colorWarning: '#faad14',
                            colorError: '#ff4d4f',
                            borderRadius: 6,
                            colorBorder: COLORS.gray4f,
                            colorBgBase: '#ffffff',
                            colorLink: COLORS.primary1,
                            motionEaseInOutCirc: '',
                            motionEaseOutCirc: ''
                        },
                        components: {
                            Image: {
                                zIndexPopupBase: 1100
                            },
                            Modal: {
                                colorPrimary: COLORS.primary1
                            },
                            DatePicker: {
                                presetsWidth: 50,
                                timeColumnWidth: 76
                            },
                            Form: {
                                fontSize: 12
                            },
                            Input: {
                                controlHeight: 34
                            },
                            Progress: {
                                defaultColor: COLORS.primary1
                            }
                        }
                    } },
                    React.createElement(AntModalGlobalStyled, null),
                    React.createElement(Application, { initialSearch: initialSearch })))))), document.getElementById('root'));
