export var KO = {
    TEST: 'TEST',
    APP_NAME: '서비스 로고',
    SUPER_ADMIN_SIDE_MENU_STORE: '상점 관리',
    SUPER_ADMIN_SIDE_MENU_DASHBOARD: '대시보드',
    SUPER_ADMIN_SIDE_MENU_INVOICES: '청구서관리',
    SUPER_ADMIN_SIDE_MENU_FRANCHISES: '프랜차이즈 관리',
    SUPER_ADMIN_SIDE_MENU_ALIMTALK_TEMPLATES: '알림톡 관리',
    SUPER_ADMIN_SIDE_MENU_PAYMENTS: '앱내 판매관리',
    SUPER_ADMIN_SIDE_MENU_MIGRATION: '마이그레이션',
    FRANCHISE_ADMIN_SIDE_MENU_LIST: '가맹점목록',
    FRANCHISE_ADMIN_SIDE_MENU_INVOICES: '이용료 정산',
    ADMIN_SIDE_MENU_STORE_MANAGEMENT: '상점 관리',
    ADMIN_SIDE_MENU_STORE_MANAGEMENT_SUB_INFO: '상점 정보 관리',
    ADMIN_SIDE_MENU_STORE_MANAGEMENT_SUB_POINT: '문자 포인트 관리',
    ADMIN_SIDE_MENU_STORE_MANAGEMENT_SUB_SUBSCRIPTION: '서비스 요금제 관리',
    ADMIN_SIDE_MENU_STORE_MANAGEMENT_SUB_APP_INVOICE: '프랜차이즈 관리',
    ADMIN_SIDE_MENU_STORE_MANAGEMENT_SUB_PYAMENT_METHODS: '결제 정보 관리',
    ADMIN_SIDE_MENU_EMPLOYEE_MANAGEMENT: '직원 관리',
    ADMIN_SIDE_MENU_EMPLOYEE_MANAGEMENT_SUB_EMPLOYEE: '직원 관리',
    ADMIN_SIDE_MENU_EMPLOYEE_MANAGEMENT_SUB_PERMISSIONS: '권한 설정',
    ADMIN_SIDE_MENU_EMPLOYEE_MANAGEMENT_SUB_INCENTIVE: '인센티브 관리',
    ADMIN_SIDE_MENU_GO_HOME: '홈으로 돌아가기',
    SIDE_MENU_GROUP_RESERVATION: '예약 관리',
    SIDE_MENU_SUB_DEPOSIT: '예약금',
    SIDE_MENU_GROUP_CUSTOMER: '고객 관리',
    SIDE_MENU_GROUP_SALES: '매출 관리',
    SIDE_MENU_GROUP_SALES_STAT: '결산 / 분석',
    SIDE_MENU_GROUP_USES: '이용내역 관리',
    SIDE_MENU_GROUP_SMS: '문자 관리',
    SIDE_MENU_GROUP_MARKETING: '마케팅',
    SIDE_MENU_SUB_RESERVATION: '예약 캘린더',
    SIDE_MENU_SUB_TRANSFERS: '예약금 입금내역',
    SIDE_MENU_SUB_CUSTOMER: '고객리스트',
    SIDE_MENU_SUB_CUSTOMER_PREPAID: '선불고객 / 포인트관리',
    SIDE_MENU_SUB_TICKET_TEMPLATE: '선불액∙정기권 관리',
    SIDE_MENU_SUB_SALES: '매출 등록/목록',
    SIDE_MENU_SUB_STOCKS: '재고 관리',
    SIDE_MENU_SUB_EXPENSES: '수입/지출관리',
    SIDE_MENU_SUB_SALES_EMPLOYEE: '직원별 결산',
    SIDE_MENU_SUB_SALES_STAT: '상점매출 분석',
    SIDE_MENU_SUB_USING_STAT: '이용률 분석',
    SIDE_MENU_SUB_USES: '이용내역 관리',
    SIDE_MENU_SUB_PREPAID: '선불 충전액 이용내역',
    SIDE_MENU_SUB_TICKET: '정기권이용 내역',
    SIDE_MENU_SUB_SMS: '문자 발송',
    SIDE_MENU_SUB_SMS_HISTORY: '문자 내역',
    SIDE_MENU_SUB_SMS_MESSAGE_TEMPLATES: '내 문자 보관함',
    SIDE_MENU_SUB_MULTI_LINK: '팅커뷰링크',
    SIDE_MENU_SUB_MARKETING: '마케팅',
    SIDE_MENU_SUB_SMS_SETTINGS: '문자 발신정보 설정',
    SIDE_MENU_SUB_SMS_AUTO_SETTINGS: '문자 자동발송 설정',
    SIDE_MENU_SUB_APP_MANAGEMENT: '앱 컨텐츠 등록/관리',
    SIDE_MENU_SUB_COUPON: '쿠폰 발송/관리',
    CALENDAR_DISPLAY_MODE_DAY: '일',
    CALENDAR_DISPLAY_MODE_WEEK: '주',
    CALENDAR_DISPLAY_MODE_MONTH: '월',
    PAGE_TITLE_RESERVATION: '스케쥴',
    PAGE_TITLE_PRODUCT_LIST: '상품관리',
    PAGE_TITLE_TICKET_TEMPLATE_LIST: '선불액∙정기권 관리'
};
