var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useMutation, useQuery } from 'react-query';
import { CustomerModel } from 'renderer/models';
import { every, isEmpty, isNilOrEmptyArray, RestClient, some, isArray, get, isNumber } from 'renderer/utils';
import { QUERY_CACHE } from 'renderer/queries';
import { CustomerTagModel } from 'renderer/models';
import notification from 'antd/lib/notification';
import { usePager } from 'renderer/utils/Pager';
import { CustomerPointModel } from '../models/CustomerPointModel';
import dayjs from 'dayjs';
import { intersection } from 'lodash';
import { ScheduleModel } from '../models/ScheduleModel';
var getCustomersByStoreId = function (options) {
    var _a = options.queryKey, _ = _a[0], pagerOptions = _a[1];
    var storeId = pagerOptions.storeId, rest = __rest(pagerOptions, ["storeId"]);
    return RestClient.getInstance()
        .post("/stores/".concat(pagerOptions.storeId, "/customers/query"), rest)
        .then(function (result) { return result.data; });
};
export var getCustomersByPhone = function (params) {
    var storeId = params.storeId, phone = params.phone;
    return RestClient.getInstance()
        .get("/stores/".concat(storeId, "/customers/phone/").concat(phone))
        .then(function (result) { return new CustomerModel(result.data); });
};
var getCustomerPointsByStoreId = function (options) {
    var _a = options.queryKey, _ = _a[0], pagerOptions = _a[1];
    var storeId = pagerOptions.storeId, rest = __rest(pagerOptions, ["storeId"]);
    return RestClient.getInstance()
        .get("/stores/".concat(pagerOptions.storeId, "/customers/point"), { params: rest })
        .then(function (result) { return result.data; });
};
var getCustomersWithTicketsByStoreId = function (options) {
    var _a = options.queryKey, _ = _a[0], pagerOptions = _a[1];
    var storeId = pagerOptions.storeId, rest = __rest(pagerOptions, ["storeId"]);
    return RestClient.getInstance()
        .get("/stores/".concat(pagerOptions.storeId, "/customers/tickets"), { params: pagerOptions })
        .then(function (result) { return result.data; });
};
export var getCustomerByStoreIdAndId = function (options) {
    var _a = options.queryKey, _ = _a[0], _b = _a[1], storeId = _b.storeId, id = _b.id;
    return RestClient.getInstance()
        .get("/stores/".concat(storeId, "/customers/").concat(id))
        .then(function (result) { return new CustomerModel(result.data); });
};
var updateCustomer = function (payload) {
    return RestClient.getInstance()
        .put("/stores/".concat(payload.storeId, "/customers"), payload)
        .then(function (result) { return new CustomerModel(result.data); });
};
var deleteCustomer = function (data) {
    return RestClient.getInstance()
        .remove("/stores/".concat(data.storeId, "/customers/").concat(data.id))
        .then(function (result) { return new CustomerModel(result.data); });
};
var getWillExpirePoint = function (options) {
    var params = options.queryKey[1];
    return RestClient.getInstance()
        .get("/stores/".concat(params.storeId, "/customers/").concat(params.customerId, "/point?type=").concat(params.type))
        .then(function (result) { var _a; return (_a = result.data) === null || _a === void 0 ? void 0 : _a.result; });
};
export var bulkCreateCustomer = function (data) {
    return RestClient.getInstance()
        .post("/stores/".concat(data.storeId, "/customers/bulk"), data.payload)
        .then(function (result) {
        var _a;
        return __assign(__assign({}, result.data), { items: (_a = result.data.items) === null || _a === void 0 ? void 0 : _a.map(function (item) { return new CustomerModel(item); }) });
    });
};
export var useCustomerWithDetailPager = function (query, config) {
    return usePager('customers.detail', query, getCustomersWithTicketsByStoreId, CustomerModel, config);
};
export var useCustomerPager = function (query, config) {
    return usePager('customers', query, getCustomersByStoreId, CustomerModel, __assign(__assign({}, config), { staleTime: 60 * 1000 * 5 }));
};
export var useCustomerPointPager = function (query, config) {
    return usePager('customers.points', query, getCustomerPointsByStoreId, CustomerPointModel, config);
};
// payments 는 정기권 매출만
export var useCustomerDetail = function (params, config) {
    return useQuery(['customerDetail', params], getCustomerByStoreIdAndId, config);
};
var customerMutationConfig = {
    onSuccess: function (data) {
        if (data === null || data === void 0 ? void 0 : data.deletedAt) {
            QUERY_CACHE.removeQueries(['customerDetail', { id: data.id, storeId: data.storeId }]);
        }
        QUERY_CACHE.invalidateQueries(['customerDetail']);
        // TODO: 필터에 맞춰서 optimise
        QUERY_CACHE.invalidateQueries(['customers']);
        QUERY_CACHE.invalidateQueries(['schedules']);
    },
    onError: function (error) {
        notification.error({ message: error.message });
    }
};
var createCustomer = function (payload) {
    return RestClient.getInstance()
        .post("/stores/".concat(payload.storeId, "/customers"), payload)
        .then(function (result) { return new CustomerModel(result.data); });
};
var mergeCustomer = function (payload) {
    return RestClient.getInstance()
        .post("/stores/".concat(payload.storeId, "/customers/merge"), payload)
        .then(function (result) { return new CustomerModel(result.data); });
};
var bulkCreateCustomerNumber = function (storeId) {
    return RestClient.getInstance()
        .put("/stores/".concat(storeId, "/customers/numbers"))
        .then(function (result) { return new CustomerModel(result.data); });
};
var removeCustomerNumber = function (storeId) {
    return RestClient.getInstance()
        .remove("/stores/".concat(storeId, "/customers/numbers"))
        .then(function (result) { return new CustomerModel(result.data); });
};
export var useCreateCustomerMutation = function (config) {
    return useMutation(createCustomer, __assign(__assign({}, customerMutationConfig), config));
};
export var useBulkCreateCustomerMutation = function (config) {
    return useMutation(bulkCreateCustomer, __assign(__assign({}, customerMutationConfig), config));
};
export var useUpdateCustomerMutation = function (config) {
    return useMutation(updateCustomer, __assign(__assign({}, customerMutationConfig), config));
};
export var useDeleteCustomerMutation = function (config) {
    return useMutation(deleteCustomer, __assign(__assign({}, customerMutationConfig), config));
};
export var useMergeCustomerMutation = function (config) {
    return useMutation(mergeCustomer, __assign(__assign({}, customerMutationConfig), config));
};
export var useBulkCreateCustomerNumber = function (config) {
    return useMutation(bulkCreateCustomerNumber, __assign(__assign({}, customerMutationConfig), config));
};
export var useRemoveCustomerNumber = function (config) {
    return useMutation(removeCustomerNumber, __assign(__assign({}, customerMutationConfig), config));
};
var getCustomerTagsByStoreId = function (options) {
    var storeId = options.queryKey[1];
    return RestClient.getInstance()
        .get("/stores/".concat(storeId, "/tags"))
        .then(function (result) { return result.data.map(function (item) { return new CustomerTagModel(item); }); });
};
var getLastCustomerNumberByStoreId = function (options) {
    var storeId = options.queryKey[1];
    return RestClient.getInstance()
        .get("/stores/".concat(storeId, "/customers/numbers"))
        .then(function (result) { return result.data; });
};
var createCustomerTag = function (payload) {
    return RestClient.getInstance()
        .post("/stores/".concat(payload.storeId, "/tags"), payload)
        .then(function (result) { return new CustomerTagModel(result.data); });
};
export var updateCustomerTag = function (payload) {
    return RestClient.getInstance()
        .put("/stores/".concat(payload.storeId, "/tags/").concat(payload.id), payload)
        .then(function (result) { return new CustomerTagModel(result.data); });
};
export var deleteCustomerTag = function (data) {
    return RestClient.getInstance()
        .remove("/stores/".concat(data.storeId, "/tags/").concat(data.id))
        .then(function (result) { return new CustomerTagModel(result.data); });
};
var mutationConfig = {
    onSuccess: function () {
        // TODO: 필터에 맞춰서 optimise
        QUERY_CACHE.invalidateQueries(['customerTags']);
        QUERY_CACHE.invalidateQueries(['customers']);
    },
    onError: function (error) {
        notification.error({ message: error.message });
    }
};
export var useCustomerTags = function (storeId, config) {
    return useQuery(['customerTags', storeId], getCustomerTagsByStoreId, __assign(__assign({}, config), { staleTime: 120000 }));
};
export var useCreateCustomerTagMutation = function (config) {
    return useMutation(createCustomerTag, __assign(__assign({}, mutationConfig), config));
};
export var useUpdateCustomerTagMutation = function (config) {
    return useMutation(updateCustomerTag, __assign(__assign({}, mutationConfig), config));
};
export var useDeleteCustomerTagMutation = function (config) {
    return useMutation(deleteCustomerTag, __assign(__assign({}, mutationConfig), config));
};
export var useLastCustomerNumber = function (storeId, config) {
    return useQuery(['lastCustomerNumber', storeId], getLastCustomerNumberByStoreId, config);
};
export var useWillExpiredPoint = function (params, config) {
    return useQuery(['willExpiredPoint', params], getWillExpirePoint, config);
};
export var customerDetailQuery = function (params) {
    return QUERY_CACHE.getQueryCache().find(['customerDetail', params]);
};
export var getCustomerSegments = function (customer) {
    var _a;
    // const customer =
    var store = QUERY_CACHE.getQueryData(['store']) || customer.store;
    var filters = ((_a = store === null || store === void 0 ? void 0 : store.preference) === null || _a === void 0 ? void 0 : _a.customerFilters) || [];
    if (!store || isEmpty(filters)) {
        return [];
    }
    var segments = filters.filter(function (filter) {
        var _a;
        var isUnion = filter.condition === 'union';
        if (isUnion) {
            var andItem = filter.data.and && filter.data.and[0];
            var orItems = andItem === null || andItem === void 0 ? void 0 : andItem.or;
            if (orItems && !isEmpty(orItems)) {
                return isIncludeSegment(customer, orItems, some);
            }
            else {
                return true;
            }
        }
        else {
            var andItems = (_a = filter.data.and) === null || _a === void 0 ? void 0 : _a.map(function (andItems) { return andItems.or && andItems.or[0]; }).filter(Boolean);
            if (andItems && !isEmpty(andItems)) {
                return isIncludeSegment(customer, andItems, every);
            }
            else {
                return true;
            }
        }
    });
    return segments;
};
export var getCustomerDefaultReservationStatus = function (customer) {
    // const customer =
    var store = QUERY_CACHE.getQueryData(['store']) || customer.store;
    var defaultStatus = store === null || store === void 0 ? void 0 : store.operationPreference.defaultStatus;
    var pendingSegments = store === null || store === void 0 ? void 0 : store.operationPreference.pendingCustomersSegmentFields;
    var customerSegments = getCustomerSegments(customer);
    var sameSegment = intersection(pendingSegments, customerSegments.map(function (item) { return item.id; })) || [];
    return (sameSegment === null || sameSegment === void 0 ? void 0 : sameSegment.length) > 0
        ? ScheduleModel.RESERVATION_STATUS.PENDING
        : defaultStatus || ScheduleModel.RESERVATION_STATUS.CONFIRMED;
};
var isIncludeSegment = function (customer, conditions, sormeOrEvery) {
    if (!customer) {
        return false;
    }
    return sormeOrEvery(conditions, function (orItem) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        // note: server convertFilterToWhere 와 동일하게 가야함
        var key = orItem.key, operator = orItem.operator, type = orItem.type, values = orItem.values;
        if (isNilOrEmptyArray(values)) {
            return true;
        }
        if (type === 'number') {
            var target = (customer.meta && customer.meta[key]) || customer[key] || 0;
            if (operator === '$between') {
                if (isNumber(values.start) && isNumber(values.end)) {
                    if (target >= values.start && target <= values.end) {
                        return true;
                    }
                }
                else if (isNumber(values.start)) {
                    if (target >= values.start) {
                        return true;
                    }
                }
                else if (isNumber(values.end)) {
                    if (target <= values.end) {
                        return true;
                    }
                }
            }
            else {
                if (operator === '$gte') {
                    if (target >= values) {
                        return true;
                    }
                }
                else if (operator === '$lte') {
                    if (target <= values) {
                        return true;
                    }
                }
            }
        }
        if (type === 'string') {
            if (key === 'address') {
                return ((_a = customer.addressString) === null || _a === void 0 ? void 0 : _a.includes) && ((_b = customer.addressString) === null || _b === void 0 ? void 0 : _b.includes(values));
            }
            else {
                return customer[key] && customer[key].includes && customer[key].includes(values);
            }
        }
        if (type === 'date') {
            var value = void 0;
            var isLastVisitedAt_1 = key === 'lastVisitedAt';
            var isBetween = operator === '$between';
            var newOp = operator.replace(/Day/g, '');
            if (operator === null || operator === void 0 ? void 0 : operator.includes('Day')) {
                if (operator === null || operator === void 0 ? void 0 : operator.includes('Day')) {
                    var dayjsValue = newOp === '$lte'
                        ? dayjs().subtract(values, 'day').endOf('day')
                        : dayjs().subtract(values, 'day').startOf('day');
                    if (key === 'lastVisitedAt') {
                        value = dayjsValue.valueOf();
                    }
                    else {
                        value = dayjsValue.toDate();
                    }
                }
            }
            else {
                var dayjsValue = void 0;
                if (isBetween) {
                    dayjsValue = [
                        values.start && dayjs(values.start).startOf('day'),
                        values.end && dayjs(values.end).endOf('day')
                    ];
                }
                else {
                    dayjsValue = operator === '$lte' ? dayjs(values).endOf('day') : dayjs(values).startOf('day');
                }
                value = isArray(dayjsValue)
                    ? dayjsValue.map(function (item) { return (isLastVisitedAt_1 ? item === null || item === void 0 ? void 0 : item.valueOf() : item === null || item === void 0 ? void 0 : item.toDate()); })
                    : isLastVisitedAt_1
                        ? dayjsValue.valueOf()
                        : dayjsValue.toDate();
            }
            // if(valu)
            var target = get(customer, isLastVisitedAt_1 ? 'meta.lastVisitedAt' : "".concat(key), isLastVisitedAt_1 ? null : '1900-01-01');
            var convertedTarget = isLastVisitedAt_1 ? target : dayjs(target).toDate();
            if (!target && !isNumber(target)) {
                return false;
            }
            return isBetween
                ? value[0] && value[1]
                    ? value[0] <= convertedTarget && value[1] >= convertedTarget
                    : value[0]
                        ? value[0] <= convertedTarget
                        : value[1] >= convertedTarget
                : newOp === '$lte'
                    ? value >= convertedTarget
                    : value <= convertedTarget;
        }
        if (type === 'enum') {
            if (key === 'tag') {
                return every(values === null || values === void 0 ? void 0 : values.map(function (tagId) { var _a; return (_a = customer.tags) === null || _a === void 0 ? void 0 : _a.find(function (item) { return Number(item.id) === Number(tagId); }); }));
            }
            if (key === 'manager') {
                return customer.managerId && values && values.includes && values.includes(customer.managerId);
            }
            if (key === 'group') {
                return customer.customerGroupId && values && values.includes && values.includes(customer.customerGroupId);
            }
            if (key === 'gender') {
                return customer.gender && values && values.includes && values.includes(customer.gender);
            }
            if (key === 'hadPrepaid' || key === 'hadTicket') {
                var isFalse = values.length === 1 && !values[0];
                if (key === 'hadPrepaid') {
                    if (values.length > 1) {
                        return true;
                    }
                    if (isFalse) {
                        return !((_c = customer.meta) === null || _c === void 0 ? void 0 : _c.hadPrepaid);
                    }
                    else {
                        return !!((_d = customer.meta) === null || _d === void 0 ? void 0 : _d.hadPrepaid);
                    }
                }
                else {
                    if (values.length > 1) {
                        return true;
                    }
                    if (isFalse) {
                        return !((_e = customer.meta) === null || _e === void 0 ? void 0 : _e.hadTicket);
                    }
                    else {
                        return !!((_f = customer.meta) === null || _f === void 0 ? void 0 : _f.hadTicket);
                    }
                }
            }
            if (key === 'availablePrepaid' || key === 'availableTickets') {
                var isFalse = values.length === 1 && !values[0];
                if (key === 'availablePrepaid') {
                    if (values.length > 1) {
                        return true;
                    }
                    if (isFalse) {
                        return (((_g = customer.meta) === null || _g === void 0 ? void 0 : _g.prepaid) || 0) <= 0;
                    }
                    else {
                        return (((_h = customer.meta) === null || _h === void 0 ? void 0 : _h.prepaid) || 0) > 0;
                    }
                }
                else {
                    if (values.length > 1) {
                        return true;
                    }
                    if (isFalse) {
                        return (((_j = customer.meta) === null || _j === void 0 ? void 0 : _j.numberOfActiveTicketsWithoutPrepaid) || 0) <= 0;
                    }
                    else {
                        return (((_k = customer.meta) === null || _k === void 0 ? void 0 : _k.numberOfActiveTicketsWithoutPrepaid) || 0) > 0;
                    }
                }
            }
        }
    });
};
