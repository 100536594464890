import isEmpty from 'lodash-es/isEmpty';
import get from 'lodash-es/get';
import times from 'lodash-es/times';
import isNumber from 'lodash-es/isNumber';
import isEqual from 'lodash-es/isEqual';
import sum from 'lodash-es/sum';
import set from 'lodash-es/set';
import groupBy from 'lodash-es/groupBy';
import some from 'lodash-es/some';
import orderBy from 'lodash-es/orderBy';
import floor from 'lodash-es/floor';
import unescape from 'lodash-es/unescape';
import isArray from 'lodash-es/isArray';
import random from 'lodash-es/random';
import isString from 'lodash-es/isString';
import sortBy from 'lodash-es/sortBy';
// import memoize from 'lodash-es/memoize';
import isFunction from 'lodash-es/isFunction';
import isObject from 'lodash-es/isObject';
import last from 'lodash-es/last';
import uniqBy from 'lodash-es/uniqBy';
import defaults from 'lodash-es/defaults';
import isError from 'lodash-es/isError';
import every from 'lodash-es/every';
import max from 'lodash-es/max';
import min from 'lodash-es/min';
import isBoolean from 'lodash-es/isBoolean';
import toPath from 'lodash-es/toPath';
import castArray from 'lodash-es/castArray';
import flow from 'lodash-es/flow';
import isDate from 'lodash-es/isDate';
import uniq from 'lodash-es/uniq';
import ceil from 'lodash-es/ceil';
import mapValues from 'lodash-es/mapValues';
import unionWith from 'lodash-es/unionWith';
import isSafeInteger from 'lodash-es/isSafeInteger';
import isNil from 'lodash-es/isNil';
import pick from 'lodash-es/pick';
import omit from 'lodash-es/omit';
import cloneDeep from 'lodash-es/cloneDeep';
import flatten from 'lodash-es/flatten';
import debounce from 'lodash-es/debounce';
import * as dayjs from 'dayjs';
var isNumberOrDate = function (value) {
    return isNumber(value) || dayjs(value).isValid();
};
// null or undefined or emptyArray
var isNilOrEmptyArray = function (value) {
    return isNil(value) || (isArray(value) && isEmpty(value));
};
var addOrRemove = function (array, item, isRemovedFunction) {
    if (isRemovedFunction === void 0) { isRemovedFunction = function (target, item) { return target === item; }; }
    var isRemoved = false;
    var newArray = array.filter(function (arrayItem) {
        if (isRemovedFunction(arrayItem, item)) {
            isRemoved = true;
            return false;
        }
        else {
            return true;
        }
    });
    if (!isRemoved) {
        newArray.push(item);
    }
    return newArray;
};
var sleep = function (delay) {
    return new Promise(function (resolve) {
        // wait 3s before calling fn(par)
        setTimeout(function () { return resolve(true); }, delay);
    });
};
export { isEmpty, sum, set, get, groupBy, some, orderBy, floor, unescape, isArray, random, isString, sortBy, 
// memoize,
isFunction, isObject, last, uniqBy, defaults, isError, times, isNumber, isEqual, every, min, max, isBoolean, toPath, castArray, flow, isDate, uniq, isNumberOrDate, ceil, mapValues, unionWith, isSafeInteger, isNil, isNilOrEmptyArray, pick, addOrRemove, sleep, omit, cloneDeep, flatten, debounce };
